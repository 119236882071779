import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IContract } from "../../typings/contract";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEye as faEyeDisabled } from "@fortawesome/free-regular-svg-icons";
import { IconButton } from "@material-ui/core";
import { COLORS } from "../../theme/theme";
import { selectFollowedContracts } from "../../redux/user/selectors";
import { addFollowedContracts, deleteFollowedContracts } from "../../redux/user/actions";

interface IProps {
  contract: IContract;
  className?: string;
}

const ContractWatchIcon: React.FC<IProps> = ({ contract, className }: IProps) => {
  const dispatch = useDispatch();
  const fContrracts = useSelector(selectFollowedContracts);
  const isContractWatched = fContrracts?.indexOf(contract.id) !== -1;

  const toggleFollowedContract = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isContractWatched) {
      dispatch(deleteFollowedContracts(contract.id));
    } else {
      dispatch(addFollowedContracts(contract.id));
    }
  };
  return (
    <>
      {isContractWatched && (
        <IconButton
          title={"odebrat zakázku ze sledovaných"}
          aria-label="odebrat zakázku ze sledovaných"
          size={"small"}
          onClick={toggleFollowedContract}
        >
          <FontAwesomeIcon icon={faEye} color={COLORS.RED} />
        </IconButton>
      )}
      {!isContractWatched && contract.active && (
        <IconButton
          title={"zařadit zakázku mezi sledované"}
          aria-label="zařadit zakázku mezi sledované"
          size={"small"}
          onClick={toggleFollowedContract}
        >
          <FontAwesomeIcon icon={faEyeDisabled} />
        </IconButton>
      )}
    </>
  );
};

export default ContractWatchIcon;
