import { format } from "date-fns";
import { cs } from "date-fns/locale";

export const formatDateShort = (dateString?: string) =>
  format(dateString ? new Date(dateString) : new Date(), "dd. MM. yyyy", { locale: cs });


export const formatDateLong = (dateString?: string) =>
  format(dateString ? new Date(dateString) : new Date(), "dd. MM. yyyy HH:mm", { locale: cs });


export const formatDateFull = (dateString?: string) =>
  format(dateString ? new Date(dateString) : new Date(), "yyyy-MM-dd'T'HH:mm:ss", { locale: cs }); //2022-04-19T10:49:30.480Z