import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useStyles from "./DesktopNavigation.styles";
import HeaderUserbox from "../HeaderUserbox/HeaderUserbox";
import { gaEventClickCddRegistration } from "../../../utils/ga";
import BadgeUnreadMsgs from "./BadgeUnreadMsgs";
import BadgeFollowedContracts from "./BadgeFollowedContracts";
import { selectUser, selectUserInit } from "../../../redux/user/selectors";
import { COLORS } from "../../../theme/theme";
import PuffLoader from "react-spinners/PuffLoader";
import { useHandleLogin } from "../../../hooks/useHandleLogin";


const DesktopNavigation: React.FC = () => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const isInit = useSelector(selectUserInit);

  const { handleLogin } = useHandleLogin()

  const handleRegister = () => gaEventClickCddRegistration();

  return (
    <Grid container justifyContent="space-between" direction="row" className={classes.menu}>
      <Grid item>
        <Link to="/verejne-zakazky" title="Katalog veřejných zakázek">
          <Button className={classes.item}>Zakázky</Button>
        </Link>
        <Link to="/zadavatele-verejnych-zakazek" title="Zadavatelé veřejných zakázek">
          <Button className={classes.item}>Zadavatelé</Button>
        </Link>
      </Grid>

      <Grid item>
        <Link to="/sledovane-zakazky" title="Sledované zakázky">
          <Button
            className={classes.item}
            startIcon={<BadgeFollowedContracts />}
          >
            Sledované zakázky
          </Button>
        </Link>
        <Link to="/filtry-a-notifikace" title="Filtry a notifikace">
          <Button className={classes.item}>Filtry a notifikace</Button>
        </Link>
        <Link to="/cenik" title="Ceník">
          <Button className={classes.item}>Ceník</Button>
        </Link>
        <Link to="/ekomunikace" title="eKomunikace">
          <Button
            className={classes.item}
            startIcon={<BadgeUnreadMsgs />}
          >
            eKomunikace
          </Button>
        </Link>
        {
          !isInit ? <span style={{ marginLeft: 20 }}><PuffLoader color={COLORS.RED} size={30} /></span> :
            (!user ? (
              <>
                <Button id="prihlasit" onClick={handleLogin} className={classes.itemProfile}>
                  Přihlášení
                </Button>
                <a onClick={handleRegister} href={`${process.env.REACT_APP_CDD_URL}/#/registrace`}>
                  <Button className={classes.itemProfile}>Nová registrace</Button>
                </a>
              </>
            ) : (
              <HeaderUserbox />
            ))
        }
      </Grid>
    </Grid>
  );
};

export default DesktopNavigation;
