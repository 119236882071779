import React from "react";
import RibbonStyles from "./Ribbon.styles";
import { COLORS } from "../../theme/theme";

interface IRibbon {
  backgroundColor?: string;
  textColor?: string;
  text?: string;
  fontSize?: string | number;
  wrapperPosition?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  labelPosition?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
}

const Ribbon: React.FC<IRibbon> = ({ text, backgroundColor, textColor, wrapperPosition, labelPosition, fontSize }) => {
  const classes = RibbonStyles();

  return (
    <div className={classes.wrapper}
         style={{
           color: textColor || "#fff",
           fontSize: fontSize || 12,
           top: wrapperPosition?.top || 14,
           right: wrapperPosition?.right || "-46px",
           backgroundColor: backgroundColor || COLORS.RED
         }}
    >
      <span className={classes.label}
            style={{
              top: labelPosition?.top || 3,
              right: labelPosition?.right || 53
            }}>{text || "Nová"}</span>
    </div>
  );
};

export default Ribbon;
