import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import PageWrap from "../../../components/PageWrap/PageWrap";
import useStyles from "./Overview.styles";
import { IUser } from "../../../typings/user";
import { Breadcrumb } from "../../../typings/breadcrumb";
import UserInformation from "./components/UserInformation/UserInformation";
import FeedbackForm from "./components/FeedbackForm/FeedbackForm";
import CddIdentity from "./components/CddIdentity/CddIdentity";
import Subscription from "./components/Subscription/Subscription";
import MobileDevice from "./components/MobileDevice/MobileDevice";

interface IProps {
  user: IUser;
  cddInformation: any;
}

const Overview: React.FC<IProps> = ({ user, cddInformation }: IProps) => {
  const classes = useStyles();
  const breadcrumb: Array<Breadcrumb> = [{ label: "Profil uživatele", path: "/profil" }];

  return (
    <PageWrap breadcrumbNavigation={breadcrumb} title="Profil uživatele">
      <Grid container spacing={3} alignItems="baseline" style={{ padding: "40px 0" }}>
        <Grid item lg={3} md={12} className={classes.detailTitle}>
          <Typography variant="h6" style={{ textTransform: "uppercase", color: "#6d706d" }}>
            CDD IDENTITA
          </Typography>
        </Grid>
        <Grid item lg={9}  className={classes.detailData}>
          <CddIdentity cddInformation={cddInformation} />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="baseline" style={{ padding: "40px 0" }}>
        <Grid item lg={3} md={12} className={classes.detailTitle}>
          <Typography variant="h6" style={{ textTransform: "uppercase", color: "#6d706d" }}>
            Platební a fakturační údaje pro E-shop
          </Typography>
        </Grid>
        <Grid item lg={9} className={classes.detailData}>
          <UserInformation user={user} />
        </Grid>
      </Grid>

      <div className={classes.strip}>
        <Grid container spacing={1} alignItems="center">
          <Grid item lg={3} md={12} xs={12} className={classes.detailTitle}>
            <Typography variant="h6" style={{ textTransform: "uppercase", color: "#6d706d" }}>
              Předplatné
            </Typography>
          </Grid>
          <Grid container item lg={9} className={classes.detailData}>
            <Subscription />
          </Grid>
        </Grid>
      </div>
      <div className={classes.strip}>
        <Grid container spacing={1} alignItems="center">
          <Grid item lg={3} md={12} xs={12} className={classes.detailTitle}>
            <Typography variant="h6" style={{ textTransform: "uppercase", color: "#6d706d" }}>
              Mobilní zařízení
            </Typography>
          </Grid>
          <Grid container item lg={9} className={classes.detailData}>
            <MobileDevice />
          </Grid>
        </Grid>
      </div>
      <div className={`${classes.strip}`}>
        <Grid container spacing={2} alignItems="baseline" style={{ padding: "40px 0" }}>
          <Grid item lg={3} md={12} xs={12} className={classes.detailTitle}>
            <Typography variant="h6" style={{ textTransform: "uppercase", color: "#6d706d" }}>
              Zpětná vazba
            </Typography>
          </Grid>
          <Grid item lg={9}>
            <FeedbackForm />
          </Grid>
        </Grid>
      </div>
    </PageWrap>
  );
};

export default Overview;
