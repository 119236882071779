import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Accordion as MuiAccordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import useStyles from "../../pages/Home/Home.styles";

interface IAccordion {
  title: string;
  description: () => JSX.Element;
}

const Accordion: React.FC<IAccordion> = ({ title, description }: IAccordion) => {
  const classes = useStyles();

  return (
    <MuiAccordion className={classes.accordionWrapper}>
      <AccordionSummary
        className={classes.accordionHeading}
        expandIcon={<ArrowDropDownIcon className={classes.accordionIcon} />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionText}>{description()}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
