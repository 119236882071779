import React, { useState } from "react";
import { AppBar, Toolbar, Hidden, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import DesktopNavigation from "../Navigation/DesktopNavigation";
import MobileNavigation from "../Navigation/MobileNavigation";
import useStyles from "./Header.styles";
import FENLogo from "../../../assets/fen-logo.png";
import Logo from "../../../assets/logonew.png";
import { MobileView } from "react-device-detect";

const MAINTENANCE_TEXT = process.env.REACT_APP_MAINTENANCE_TEXT;
const MAINTENANCE_SHOW = process.env.REACT_APP_MAINTENANCE_SHOW;

const Header: React.FC = () => {
  const classes = useStyles();

  const [showMobileAppInfo, setShowMobileAppInfo] = useState(true);

  return (
    <>
      <AppBar className={classes.appBar} elevation={0}>
        <Container maxWidth="lg">
          <Toolbar>
            <a href={process.env.REACT_APP_FEN_URL} target={"_blank"} rel={"noreferrer"} title="fen.cz"
               style={{ marginRight: 5 }}>
              <img alt={"FEN logo"} src={FENLogo} width="100%" className={classes.logoFen} />
            </a>
            <Link to="/" className={classes.logoLink} title={"Portál Dodavatele"}>
              <img alt={"PoDo logo"} src={Logo} width="100%" height="100%" className={classes.logo} />
            </Link>
            <Hidden mdDown>
              <nav>
                <DesktopNavigation />
              </nav>
            </Hidden>
            <Hidden lgUp>
              <MobileNavigation />
            </Hidden>
          </Toolbar>
        </Container>
        <MobileView>
          {showMobileAppInfo &&
            <div className={classes.useMobileAppInfo}>
              Doporučujeme použít <a href="https://cdd.fen.cz/#/mobilni-aplikace">mobilní aplikaci</a>
              <span onClick={() => setShowMobileAppInfo(false)}>&times;</span>
            </div>
          }
        </MobileView>
        {(MAINTENANCE_TEXT && MAINTENANCE_SHOW && (MAINTENANCE_SHOW === '1' || MAINTENANCE_SHOW === 'true')) &&
          <div className={classes.maintenanceInfo}>
            {MAINTENANCE_TEXT}
          </div>
        }
      </AppBar>
    </>
  );
};

export default Header;
