import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme/theme";

export const AppStyles = makeStyles(() => ({
  wrap: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flexGrow: 2,
    paddingTop: 70,
    minHeight: 250,
    overflow: "hidden",
    "& a": {
      textDecoration: "none"
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 64
    }
  }
}));

export default AppStyles;
