import React from "react";
import SlideInStyles from "./SlideIn.styles";

const SlideIn: React.FC<{ delay?: number }> = ({ children, delay = 0.1 }) => {
  const classes = SlideInStyles();
  return (
    <div className={classes.transition} style={{ animationDelay: `${delay}s` }} key={Math.random().toString()}>
      {children}
    </div>
  );
};

export default SlideIn;
