import { RootState } from "../root-reducer";
import * as lodash from "lodash";

const selectPhrase = (state: RootState) => state.global.visibleFilter.phrase;

export const selectVisibleFilter = (state: RootState) => state.global.visibleFilter;

export const selectVisibleFilterWithoutId = (state: RootState) => lodash.omit(state.global.visibleFilter, "id");

export const filterExists = (state: RootState) => state.global.visibleFilterCreated;

export default selectPhrase;
