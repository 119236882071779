import React from "react";
import { Paper, Grid } from "@material-ui/core";
import { Rating, Skeleton } from "@material-ui/lab";
import useStyles from "./ContractCard.styles";
import { COLORS } from "../../theme/theme";

const ContractCardPlaceholder: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Paper className={classes.card} elevation={1}>
        <div className={classes.cardTitle}>
          <Skeleton width="80%" height={35} style={{ background: COLORS.RED }} />
        </div>
        <Rating className={classes.cardRating} value={5} style={{ color: "rgb(169 169 169)" }} readOnly />
        <Grid container spacing={3}>
          <Grid item sm={7}>
            <Skeleton width={"100%"} height={25} />
            <Skeleton width={"80%"} height={25} />
          </Grid>
          <Grid item sm={5}>
            <Skeleton width={200} height={25} />
            <Skeleton width={300} height={25} />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ContractCardPlaceholder;
