import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../theme/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    transition: "0.5s",
    background: "white",
    overflow: "hidden",
    padding: "10px 30px",
    borderRadius: 0,
    boxShadow: "rgb(0 0 0 / 4%) 0px 3px 5px",
    marginBottom: 2,
    [theme.breakpoints.down("md")]: {
      paddingRight: 70
    },
    position: "relative",
    "&:hover": {
      backgroundColor: "#f7f7f7"
    }
  },
  description: {
    marginTop: 0,
    marginBottom: 0
  },
  cardTitle: {
    fontSize: 21,
    fontWeight: 800,
    color: `${COLORS.RED} !important`,
    maxWidth: "85%",
    display: "flex",
    alignItems: "center",
    marginTop: "0.83em",
    marginBottom: "0.83em"
  },
  cardRatingWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  cardRating: {
    marginBottom: 15,
    color: COLORS.RED,
    fontSize: "1.1rem",
    width: 125
  },
  cardTags: {
    marginBottom: 15,
    padding: "2px 8px"
  },
  cardSubtitle: {
    fontSize: 16,
    marginBottom: 20
  },
  minLine: {
    display: "flex"
  },
  minTitle: {
    fontWeight: 800,
    width: 125,
    [theme.breakpoints.down("md")]: {
      width: 100
    },
    flex: "none",
    marginRight: 10
  },
  minText: {},
  chipWrap: {
    position: "absolute",
    right: 35,
    bottom: 35
  },
  chip: {
    backgroundColor: COLORS.RED
  },
  favWrap: {
    position: "absolute",
    top: 15,
    right: 40,
    width: 35,
    textAlign: "center"
  },
  favWrapMsg: {
    position: "absolute",
    top: 60,
    right: 40,
    width: 35,
    textAlign: "center"
  },
  link: {
    fontWeight: 600
  }
}));

export default useStyles;
