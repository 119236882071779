import { createStyles, makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../../theme/theme";

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    companiesWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: "1rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    companyImage: {
      height: "2.31rem",
      marginBottom: "0.7rem"
    },
    iconContainer: {
      display: "flex",
      alignContent: "center"
    },
    separator: {
      height: 1,
      marginBottom: "1.875rem",
      display: "block",
      background: "rgba(117,117,117,0.8)",
      width: "100%"
    },
    socialIcon: {
      marginRight: 15,
      color: "#fff !important",
      "&:hover": {
        color: `${COLORS.RED} !important`
      }
    },
    footer: {
      width: "100%",
      background: COLORS.BLUE,
      color: "white",
      fontWeight: 300,
      padding: "2.5rem 0 1.5rem",
      "& a": {
        textDecoration: "none",
        color: "inherit"
      }
    },
    title: {
      fontSize: 19,
      marginBottom: 10,
      fontWeight: 500
    },
    footerText: {
      fontSize: 16,
      lineHeight: "27px",
      marginBottom: 15
    },
    contact: {
      marginTop: 15
    },
    icon: {
      paddingRight: 10,
      fontSize: 13
    },
    socialContainer: {
      paddingTop: 5,
      marginBottom: 25
    },
    link: {
      color: "#fff !important",
      "&:visited": {
        color: "#fff !important"
      },
      "&:hover": {
        color: `${COLORS.RED} !important`
      }
    },
    mobileAppLink: {
      fontSize: 19,
      display:'block',
      marginBottom: 10
    }
  })
);

export default useStyles;
