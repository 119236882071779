import { createStyles, makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../theme/theme";
import bg from "../../assets/bg.webp";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%"
    },
    hpWrap: {
      color: COLORS.LIGHTGREY
    },
    topWrap: {
      background: `url(${bg}) no-repeat center`,
      backgroundSize: "cover",
      width: "auto",
      height: "auto",
      textAlign: "center",
      paddingTop: 150,
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        padding: "50px 0"
      }
    },
    introBlock: {
      margin: "0 auto",
      maxWidth: 550
    },
    midNumText: {
      fontSize: 28,
      fontWeight: 300
    },
    midNum: {
      color: COLORS.GOLD,
      fontSize: 36,
      lineHeight: "44px",
      padding: "0 15px"
    },
    midLine: {
      marginTop: 30,
      textAlign: "center"
    },
    bottomWrap: {
      background: COLORS.BGBLUEDARK,
      overflow: "hidden"
    },
    intro1: {
      fontSize: 32,
      lineHeight: "50px",
      fontWeight: 300,
      textTransform: "uppercase",
      marginBottom: 15,
      [theme.breakpoints.down("xs")]: {
        fontSize: 28,
        lineHeight: "35px"
      }
    },
    intro2: {
      color: COLORS.GOLD,
      fontWeight: 800,
      fontSize: 50,
      lineHeight: "50px",
      textTransform: "uppercase",
      marginBottom: 55,
      [theme.breakpoints.down("xs")]: {
        fontSize: 41,
        marginBottom: 45
      }
    },
    intro3: {
      fontSize: 24,
      lineHeight: "29px",
      marginBottom: 40,
      display: "flex",
      alignItems: "center"
    },
    introWrapper: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row"
      },
      justifyContent: "space-around",
      marginBottom: 20
    },
    introSectionWrapper: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column"
      }
    },
    chooseCategoryButton: {
      color: "#fff",
      fontSize: 16,
      padding: "40px 0"
    },
    modalLink: {
      cursor: "pointer",
      textDecoration: "underline"
    },
    modalLinkIcon: {
      fontSize: 20,
      marginRight: 10,
      marginBottom: -2
    },
    introLinkWrap: {
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start"
      }
    },
    introLink: {
      color: "#fff",
      textAlign: "center",
      textDecoration: "underline",
      fontSize: 16,
      textTransform: "none"
    },
    introTitle: {
      color: "#fff",
      marginBottom: 2
    },
    introTitleIcon: {
      marginRight: 8,
      fontSize: 20,
      marginBottom: -2
    },
    introSubTitle: {
      fontSize: 13,
      textAlign: "left",
      paddingLeft: 38
    },

    hpListTitle: {
      fontWeight: 800,
      fontSize: 36,
      lineHeight: "40px",
      color: COLORS.GOLD,
      marginBottom: 20
    },
    hpListText: {
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: 300
    },
    hpListImg: {
      maxWidth: "140%",
      height: "auto",
      marginLeft: "-20%",
      marginRight: "-20%",
      marginBottom: "-20%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0
      }
    },
    hpBoxImg: {
      overflow: "visible",
      paddingTop: 30
    },
    centerBox: {
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      height: "100%",
      maxWidth: 450,
      margin: "20px auto"
    },
    faqWrap: {
      marginTop: 150,
      marginBottom: 100,
      fontSize: 16
    },
    hpTitle: {
      fontWeight: 800,
      fontSize: 36,
      lineHeight: "40px",
      color: COLORS.GOLD,
      marginBottom: 60
    },
    accordionWrapper: {
      background: "none",
      boxShadow: "none",
      color: COLORS.LIGHTGREY,
      marginBottom: 30
    },
    accordionText: {
      fontWeight: 300,
      padding: "20px 16px"
    },
    text: {
      display: "flex",
      flexDirection: "column"
    },
    accordionHeading: {
      borderRadius: 12,
      background: COLORS.RED,
      fontWeight: 500,
      fontSize: 18
    },
    accordionIcon: {
      color: COLORS.LIGHTGREY, fontSize: 60, margin: "-20px"
    },
    newsWrap: {
      paddingTop: 40,
      marginBottom: 150
    },
    tosWrapper: {
      display: "flex"
    },
    tosButton: {
      margin: "0 auto"
    },
    moreContractsWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    moreContractsButton: {
      marginTop: 20,
      minWidth: 65
    },
    mobileOrder: {
      [theme.breakpoints.down("sm")]: {
        order: 2
      }
    },
    hpNews:{
      padding: '40px 0 60px 0',
      background: COLORS.BGBLUE,
    }
  })
);

export default useStyles;
