import { makeStyles } from "@material-ui/core/styles";

const FeedbackFormStyles = makeStyles(() => ({
  text: {
    marginBottom: 8
  },
  input: {
    background: "white",
    borderRadius: 4
  }
}));

export default FeedbackFormStyles;
