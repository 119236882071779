import { useSelector } from "react-redux";
import SlideIn from "../SlideIn/SlideIn";
import RouteStyles from "./Route.styles";
import { Star } from "@material-ui/icons";
import { Route as RouterRoute } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { RouteProps as ReactRouteProps, useHistory } from "react-router-dom";
import { Button, Typography, Box, Card } from "@material-ui/core";
import { findLowestAllowedRole, getRoleWeight } from "../../utils/roleWeight";
import { gaEventClickCddLogin } from "../../utils/ga";
import { useKeycloakActions } from "../../hooks/useKeycloakActions";
import { selectUser, selectUserInit } from "../../redux/user/selectors";
import LoadingBox from "../LoadingBox/LoadingBox";

export interface RouteProps extends ReactRouteProps {
  allowedLevels?: string | Array<"free" | "profi" | "premium" | "excellent">;
  notAllowedTitleText?: string;
  notAllowedBodyText?: string;
  loginRequired?: boolean;
}

const Route: React.FC<RouteProps> = ({
  allowedLevels,
  loginRequired = false,
  notAllowedTitleText,
  notAllowedBodyText,
  ...otherProps
}) => {
  const [lowestAllowedLevel, setLowestAllowedLevel] = useState<number>(1);
  const classes = RouteStyles();
  const history = useHistory();
  const { keycloakCreateLoginUrl } = useKeycloakActions();
  const user = useSelector(selectUser);
  const isInit = useSelector(selectUserInit);


  useEffect(() => {
    if (typeof allowedLevels === "object" && allowedLevels) {
      setLowestAllowedLevel(findLowestAllowedRole(allowedLevels));
    }
    if (typeof allowedLevels === "string" && allowedLevels) {
      setLowestAllowedLevel(getRoleWeight(allowedLevels));
    }
  }, []);

  const handleLogin = () => {
    gaEventClickCddLogin();
    const url = keycloakCreateLoginUrl(window.location.href);
    window.location.assign(url);
  };
  if (!isInit) {
    return <LoadingBox />;
  }

  if (loginRequired && !user) {
    return (
      <div className={classes.paper}>
        <h2 id="transition-modal-title">Pro tuto funkcionalitu se musíte přihlásit.</h2>
        <div id="transition-modal-description">
          <div>
            <div>Pro využití této funkcionality se musíte přihlásit pomocí CDD.</div>
            <div>
              <Button className={classes.button} variant="contained" color="primary" size="large" onClick={handleLogin}>
                Přihlásit se
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (allowedLevels && user?.activeSubscription.level && getRoleWeight(user?.activeSubscription.level) >= lowestAllowedLevel) {
    return <RouterRoute {...otherProps} />;
  }

  if (!allowedLevels) {
    return <RouterRoute {...otherProps} />;
  }

  return (
    <Box height="100%" className={classes.paper}>
      <Card elevation={0} className={classes.card}>
        {user ? (
          <>
            <SlideIn delay={0}>
              <Button variant="outlined" className={classes.lockedButton} startIcon={<Star />} disabled>
                Prémiová služba
              </Button>
            </SlideIn>
            <div>
              <div>
                <SlideIn delay={0.1}>
                  <Typography variant="h4" className={classes.textTitle}>
                    {notAllowedTitleText}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.textBody}>
                    {notAllowedBodyText}
                  </Typography>
                </SlideIn>
              </div>
              <SlideIn delay={0.2}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => history.push("/cenik")}
                >
                  Přejít do ceníku
                </Button>
              </SlideIn>
            </div>
          </>
        ) : (
          <>
            <SlideIn delay={0}>
              <Button variant="outlined" className={classes.lockedButton} startIcon={<Star />} disabled>
                Sekce uzamčena
              </Button>
            </SlideIn>
            <div>
              <SlideIn delay={0.1}>
                <Typography variant="h4" className={classes.textTitle}>
                  {notAllowedTitleText}
                </Typography>
                <Typography variant="subtitle1" className={classes.textBody}>
                  {notAllowedBodyText}
                </Typography>
                <Typography variant="subtitle2" />
              </SlideIn>
              <SlideIn delay={0.2}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleLogin}
                >
                  Přihlásit se
                </Button>
              </SlideIn>
            </div>
          </>
        )}
      </Card>
    </Box>
  );
};

export default Route;
