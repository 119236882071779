import React from "react";
import { Badge } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../theme/theme";
import { useCountUnreadAll } from "@qcm/ekom-lib";
import { useSelector } from "react-redux";
import { selectUserCanEkom } from "../../../redux/user/selectors";
import { useUserHasToken } from "../../../hooks/useUserHasToken";


const BadgeUnreadMsgsIn = ({ countUnread }: { countUnread: number }) => {
  return (
    <Badge badgeContent={countUnread} color="primary" showZero={false}>
      <FontAwesomeIcon icon={faEnvelope} color={COLORS.RED} />
    </Badge>
  );
};

const BageUnreadMsgLoadWrap = () => {
  const { countUnread } = useCountUnreadAll();
  return <BadgeUnreadMsgsIn countUnread={countUnread} />;
};

const BadgeUnreadMsgs = () => {
  const userCanEkom = useSelector(selectUserCanEkom);
  const userHasToken = useUserHasToken();

  return (userCanEkom && userHasToken) ? <BageUnreadMsgLoadWrap /> : <BadgeUnreadMsgsIn countUnread={0} />;
};

export default BadgeUnreadMsgs;
