import { RootState } from "../root-reducer";
import { IUser } from "../../typings/user";
import SubscriptionPlan from "../../typings/subscriptionPlan";

export const selectFollowedContracts = (state: RootState) =>
  state.user.followedContracts.map(f => f.contractId) || [];

export const selectUser = (state: RootState): IUser | null => state.user.user;

export const selectUserInit = (state: RootState) => state.user.init;

export const selectUserCanEkom = (state: RootState) => !!state.user.user;

export const selectUserName = (state: RootState) => {
  const { user, cddInformation } = state.user;
  return cddInformation?.user?.firstName && cddInformation?.user?.lastName
    ? `${cddInformation?.user?.firstName} ${cddInformation?.user?.lastName}`
    : `${user?.firstName} ${user?.lastName}`;
};

export const selectUserCanCreateFilter = (state: RootState) => {
  const { user, subscriptionLimit } = state.user;
  const { filters } = state.filters;
  const max = subscriptionLimit.filters || 0;
  return user && filters.length < max;
};


export const selectUserRoles = (state: RootState) => {
  const level = state.user.user?.activeSubscription?.level ?? "";
  const roles: string[] = [];
  if (state.user.user) {
    roles.push(SubscriptionPlan.FREE);
    if (level === SubscriptionPlan.PROFI) {
      roles.push(SubscriptionPlan.PROFI);
    } else if (level === SubscriptionPlan.PREMIUM) {
      roles.push(SubscriptionPlan.PROFI, SubscriptionPlan.PREMIUM);
    } else if (level === SubscriptionPlan.EXCELLENT) {
      roles.push(SubscriptionPlan.PROFI, SubscriptionPlan.PREMIUM, SubscriptionPlan.EXCELLENT);
    }
  }
  return roles;
};

export const selectUserCanEkomCreateMsg = (state: RootState) => {
  const userRoles = selectUserRoles(state);
  return userRoles.includes(SubscriptionPlan.PREMIUM);
};

export const selectUserIsExcellent = (state: RootState) => {
  const level = state.user.user?.activeSubscription?.level ?? "";
  return level === SubscriptionPlan.EXCELLENT;
};

