import format from "date-fns/format";
import { cs } from "date-fns/locale";
import schema from "../components/FormFilter/schema";
import * as lodash from "lodash";
import { InferType } from "yup";
import { NEWFILTER } from "../redux/filters/filters";
import { Submitter } from "../typings/submitter";
import { IFilter, IFilterBackend, IFilterResponse } from "../typings/filters";
import { formatDateFull } from "./date";

export const dateForFilter = (date: string | null | undefined | Date, dateFormat: string = "yyyy-MM-dd") => {
  if (!date || date.toString() === "Invalid Date" || (typeof date !== "string" && (!date as any) instanceof Date)) return null;

  return format(new Date(date), dateFormat, { locale: cs });
};

export const transformFilter = (filter: IFilterResponse): IFilter => {
  return {
    id: filter.id,
    name: filter.currentVersion.name,
    notifyActiveFrom: filter.notifyActiveFrom,
    ...filter.currentVersion.params
  };
};
export const transformFilters = (filters: IFilterResponse[]): IFilter[] =>
  filters.map(transformFilter);


export const transformFilterValuesForCrud = (filter: IFilter, isCreate?: boolean): IFilterBackend => {

  const { id, name, notifyActiveFrom, full, statuses, ...otherFilter } = filter;
  return {
    currentVersion: {
      name: name,
      params: {
        ...otherFilter,
        phrase: otherFilter.phrase?.trimEnd() ? otherFilter.phrase?.trimEnd() : undefined,
        tags: Array.from(new Set(otherFilter.tags)),
        beginsAtFrom: dateForFilter(otherFilter.beginsAtFrom),
        beginsAtTo: dateForFilter(otherFilter.beginsAtTo),
        publishedAtFrom: dateForFilter(otherFilter.publishedAtFrom),
        publishedAtTo: dateForFilter(otherFilter.publishedAtTo),
        offersDeadlineAtFrom: dateForFilter(otherFilter.offersDeadlineAtFrom),
        offersDeadlineAtTo: dateForFilter(otherFilter.offersDeadlineAtTo),
        submitterName: otherFilter.submitterName || null,
        profileNumber: otherFilter.profileNumber || null,
        submitterId: otherFilter.submitterId || null
      }
    },
    notifyActiveFrom: isCreate ? formatDateFull() : (notifyActiveFrom ? notifyActiveFrom : null)
  };
};

export const compareFilterWithFormData = (filter: IFilter, formData: InferType<typeof schema>) => {
  const pickedFilter = lodash.pick(filter, Object.keys(formData));

  return lodash.isEqual(pickedFilter, lodash.omit(formData, "full"));
};

export const isFormEmpty = (formData: InferType<typeof schema>) => {
  const omitValues = [
    "id",
    "name",
    "alternativeSearch",
    "full",
    "includeWithoutCpvCodes",
    "includeWithoutExecutionPlaces",
    "includeSubCpvCodes",
    "notifyActiveFrom",
    "kinds",
    "procedureKinds",
    "createdAtFrom",
    "createdAtTo",
    "user"
  ];

  const formValues = lodash.omit(formData, omitValues);
  const newFilter = lodash.omit(NEWFILTER, omitValues);

  return lodash.isEqual(formValues, lodash.pick(newFilter, Object.keys(formValues)));
};

export const createNewFilterForSubmitter = (submitter: Submitter) => {
  const newFilter: IFilter = {
    ...NEWFILTER,
    submitterId: submitter.id,
    name: `Zakázky zadavatele ${submitter.name}`,
    notifyActiveFrom: formatDateFull()
  };

  return newFilter;
};
