import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "./theme";

const globalStyles = makeStyles(() => ({
  colorPrimary: {
    color: COLORS.RED
  },
  textBlack50: {
    color: COLORS.BLACK,
    opacity: 0.5
  },
  fontWeightBold: {
    fontWeight: 700
  },
  textCenter: {
    textAlign: "center"
  },
  disableTextTransform: {
    textTransform: "none"
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: 0,
    transform: "translate(-50%, -50%)"
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: 0,
    transform: "translate(-50%, -50%)"
  },
  pl3: {
    paddingLeft: "1rem"
  },
  pr3: {
    paddingRight: "1rem"
  },
  button: {
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 12,
    padding: "15px 35px",
    margin: "10px 10px 30px 10px"
  }
}));

export default globalStyles;
