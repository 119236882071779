import {
  deleteFilterFailure, deleteFilterStart,
  deleteFilterSuccess, fetchFilterContractsFailure, fetchFilterContractsStart, fetchFilterContractsSuccess,
  fetchUserFilterDetailFailure, fetchUserFilterDetailStart,
  fetchUserFilterDetailSuccess, fetchUserFiltersFailure, fetchUserFiltersStart, fetchUserFiltersSuccess,
  saveFilterFailure, saveFilterStart,
  saveFilterSuccess, toggleNotificationFailure, toggleNotificationStart, toggleNotificationSuccess
} from "./filters";
import selectFilters, { selectFilterContracts, selectPage, selectRowsPerPage } from "./selectors";
import { parseErrorSync } from "../../utils/api";
import { AppThunk } from "../store";
import { toast } from "react-toastify";
import { filterExists } from "../global/selectors";
import { transformFilter, transformFilters, transformFilterValuesForCrud } from "../../utils/filter";
import { gaEventFilterAdd, gaEventFilterDelete, gaEventFilterShow, gaEventFilterUpdate } from "../../utils/ga";
import { changeFilter } from "../global/global";
import { history, scrollIntoElementViewByClass } from "../../utils/window";
import PodoApi from "../../api/PodoApi";
import { IFilter } from "../../typings/filters";
import * as lodash from "lodash";

export const fetchDetail = (filterId: string): AppThunk => async (dispatch, getState) => {
  dispatch(fetchUserFilterDetailStart());
  const filters: Array<IFilter> = selectFilters(getState());

  try {
    const filterAlreadyFetched = filters.find((userFilter: IFilter) => userFilter.id === filterId);

    if (!filterAlreadyFetched) {
      const data = await PodoApi.fetchUserFilterDetail(filterId);
      const transformData = transformFilter(data);
      dispatch(fetchUserFilterDetailSuccess(transformData));
    } else {
      dispatch(fetchUserFilterDetailSuccess(filterAlreadyFetched));
    }
  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(fetchUserFilterDetailFailure());
    toast.error(message);
  }
};


export const saveFilter = (filter: IFilter, markAsCreated?: boolean): AppThunk => async (dispatch, getState) => {
  const filterData = lodash.omit(filter, "id")
  const valid = await PodoApi.validateFilterParams(filterData)
    .then(res => {
      if (!res.valid) {
        toast.error(`Filtr není validní. Prosím zkontrolujte jeho nastavení, především klíčová slova.`);
      } else {
        return true;
      }
    }).catch(e => {
      const message = parseErrorSync(e, `Nepodařilo se provést validaci filtru.`);
      toast.error(message);
    });

  if(!valid){
    return
  }

  dispatch(saveFilterStart());
  const exists = filterExists(getState());
  const transformedFilter = transformFilterValuesForCrud(filter, !exists);

  try {
    if (!exists) {
      const data = await PodoApi.createUserFilter(transformedFilter);
      gaEventFilterAdd();
      dispatch(saveFilterSuccess());
      dispatch(changeFilter({ id: data.id }));
      toast(`Filtr úspěšně vytvořen.`, { type: "success" });
    } else {
      await PodoApi.updateUserFilter(filter.id || "", transformedFilter);
      gaEventFilterUpdate();
      dispatch(saveFilterSuccess());
      toast(`Filtr úspěšně uložen.`, { type: "success" });
    }
    history.push(`/filtry-a-notifikace`);
  } catch (e: any) {
    const message = parseErrorSync(e);
    dispatch(saveFilterFailure());
    toast.error(message);
  }
};

export const deleteFilter = (filter: IFilter): AppThunk => async (dispatch) => {
  dispatch(deleteFilterStart());
  try {
    await PodoApi.deleteUserFilter(filter.id || "");
    gaEventFilterDelete();
    dispatch(deleteFilterSuccess(filter.id || ""));
    toast.success(`Filtr úspěšně smazán.`);
  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(deleteFilterFailure());
    toast.error(message);
  }
};

export const fetchFilters = (): AppThunk => async (dispatch) => {
  dispatch(fetchUserFiltersStart());
  try {
    const data = await PodoApi.fetchUserFilters();
    const transformedFilters = transformFilters(data);
    dispatch(fetchUserFiltersSuccess(transformedFilters));
  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(fetchUserFiltersFailure(message));
    toast.error(message);
  }
};

export const fetchFilterContracts = (filter: IFilter, omit?: boolean): AppThunk => async (dispatch, getState) => {
  dispatch(fetchFilterContractsStart({ omit: omit }));
  const page = selectPage(getState());
  const filterContracts = selectFilterContracts(getState());
  const rowsPerPage = selectRowsPerPage(getState());

  try {
    const transformedFilter = transformFilterValuesForCrud(filter);
    const filterParams = transformedFilter.currentVersion.params;
    const { data, results } = await PodoApi.fetchContractsForFilter(filterParams, page, rowsPerPage);
    gaEventFilterShow();
    dispatch(
      fetchFilterContractsSuccess({
        contracts: page === 1 || omit ? data : filterContracts.concat(data),
        results
      })
    );
  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(fetchFilterContractsFailure(message));
    toast.error(message);
  }

  if (page > 1) {
    if (omit) {
      scrollIntoElementViewByClass("contract-card");
    } else {
      scrollIntoElementViewByClass("contract-card", filterContracts.length);
    }
  }
};

export const toggleNotification = (filterId: string, filter: IFilter): AppThunk => async (dispatch) => {
  dispatch(toggleNotificationStart());
  try {
    const transformFilter = transformFilterValuesForCrud(filter);
    await PodoApi.updateUserFilter(filterId, transformFilter);
    dispatch(toggleNotificationSuccess());
    toast(`Notifikace byly úspěšně ${filter.notifyActiveFrom ? "zapnuty" : "vypnuty"}`, { type: "success" });
  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(toggleNotificationFailure());
    toast.error(message);
  }
};
