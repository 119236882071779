import { RootState } from "../root-reducer";
import { IFilter } from "../../typings/filters";

export const selectFilters: (state: RootState) => Array<IFilter> = (state: RootState) => state.filters.filters;

export const selectFilterContracts = (state: RootState) => state.filters.filterContracts.data;

export const selectPage = (state: RootState) => state.filters.filterContracts.page;

export const selectRowsPerPage = (state: RootState) => state.filters.filterContracts.size;

export const selectStatus = (state: RootState) => state.filters.status;

export default selectFilters;
