import React, { useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { AttachMoney, LibraryBooks, People, Search } from "@material-ui/icons";
import CountUp from "react-countup";
import Chart from "../../../../components/Chart/Chart";
import StatisticsStyles from "../../../../components/Statistics/Statistics.styles";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/root-reducer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { SortField, SortOrder } from "../../../../enum/sort";
import { fetchStatistics } from "../../../../redux/global/actions";
import { getPreviousMonth } from "../../../../utils/common";
import { useInView } from "react-intersection-observer";

const Statistics: React.FC = () => {
  const dispatch = useDispatch();
  const statisticsClasses = StatisticsStyles();
  const { loading, data } = useSelector((state: RootState) => state.global.statistics);

  useEffect(() => {
    dispatch(fetchStatistics());
  }, []);

  const catalogLink = queryString.stringify({
    phrase: "",
    field: SortField.CREATEAT,
    order: SortOrder.DESC
  });

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  return (
      <div className={statisticsClasses.midWrap}>
        <div className={statisticsClasses.chartSectionWrapper}>
          <div className={statisticsClasses.onlyLarge}></div>
          <div className={statisticsClasses.chartWrapper}>
            <div className={statisticsClasses.midTitle}>Nové zakázky za posledních 12 hodin</div>
            <Chart/>
            <Link to={`/verejne-zakazky?${catalogLink}`} title="Katalog veřejných zakázek">
            <span className={statisticsClasses.catalogLink}>
              <FontAwesomeIcon icon={faArrowRight} style={{marginRight: 5}}/>
              Katalog zakázek
            </span>
            </Link>
          </div>
        </div>
        <div ref={ref}>
          {inView && <Grid container className={statisticsClasses.cardWrapper} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3} style={{paddingTop: 30}}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardContent}>
                    <LibraryBooks style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.text}>
                    <CountUp end={loading || !data ? 0 : data.overall.contracts} duration={1} separator=" "/>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    zakázek
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} style={{paddingTop: 30}}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardContent}>
                    <AttachMoney style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.text}>
                    <CountUp
                        end={loading || !data ? 0 : data.overall.moneyAmount / 1000000000}
                        duration={1}
                        separator=" "
                        decimals={2}
                        decimal=","
                    />
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    mld. korun
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} style={{paddingTop: 30}}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardContent}>
                    <People style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.text}>
                    <CountUp end={loading || !data ? 0 : data.overall.submitters} duration={1} separator=" "/>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    zadavatelů
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} style={{paddingTop: 30}}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardContent}>
                    <Search style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.text}>
                    <CountUp end={loading || !data ? 0 : data.overall.activeContracts} duration={1} separator=" "/>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    aktivních zakázek
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>}
          {inView && <Grid container justifyContent={'center'} className={statisticsClasses.cardWrapper} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3} md-push={3} style={{paddingTop: 30}}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardContent}>
                    <Search style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.text}>
                    <CountUp end={loading || !data ? 0 : data.overall.contractsLastMonth} duration={1}
                             separator=" "/>{" "}
                    <Typography variant="h6" component="span">
                      zakázek
                    </Typography>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    {getPreviousMonth() || "poslední ukončený měsíc"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} style={{paddingTop: 30}}>
              <Card className={statisticsClasses.card}>
                <CardContent>
                  <div className={statisticsClasses.cardContent}>
                    <LibraryBooks style={{fontSize: "3rem"}}/>
                  </div>
                  <Typography variant="h3" component="div" className={statisticsClasses.text}>
                    <CountUp end={loading || !data ? 0 : data.overall.contractsLastYear} duration={1}
                             separator=" "/>{" "}
                    <Typography variant="h6" component="span">
                      zakázek
                    </Typography>
                  </Typography>
                  <Typography variant="h5" component="div" className={statisticsClasses.text}>
                    {`rok ${new Date().getFullYear() - 1}` || "poslední ukončený rok"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>}
        </div>
      </div>
  );
};

export default Statistics;
