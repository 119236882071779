import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "../../Overview.styles";
import { Button } from "@material-ui/core";
import PodoApi from "../../../../../api/PodoApi";
import LoadingBox from "../../../../../components/LoadingBox/LoadingBox";
import { toast } from "react-toastify";

interface IDevice {
  id: string,
  owner: string,
  mobileDeviceId: string,
  platformId: string,
  name: string,
  vendor: string,
  model: string,
  createdAt: string,
  updatedAt: string
}

const MobileDevice = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<IDevice[]>([]);

  const fetchDevices = () => {
    setLoading(true);
    PodoApi.getDevices()
      .then((data) => {
        setDevices(data);
      })
      .catch((err) => {
        toast.error("Chyba načtení registrovaných mobilních zařízení");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchDevices();
  }, []);

  const clickRemove = (deviceId: string) => {
    PodoApi.deleteDevice(deviceId)
      .then((data) => {
        toast.success("Registrované zařízení bylo odebráno");
      })
      .catch((err) => {
        toast.error("Chyba odebrání registrovaného zařízení");
      })
      .finally(() => {
        fetchDevices();
      });
  };

  if (loading) {
    return <LoadingBox />;
  }
  if (!devices.length) {
    return <>Žádné registrované zařízení</>;
  }

  return (<>
    {devices.map(device =>
      <Grid container item spacing={1} xs={12} sm={10} lg={12} alignItems="center">
        <Grid item xs={8}>
          <div className={classes.detailTitle}>{device.vendor} / {device.name}</div>
        </Grid>
        <Grid item xs={4}>
          <Button variant={"outlined"} className={classes.actionButton} onClick={() => clickRemove(device.id)}>
            Odstranit
          </Button>
        </Grid>
      </Grid>
    )}
  </>);
};

export default MobileDevice;
