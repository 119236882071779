import React, { useState } from "react";
import { Button, Collapse, Divider, Drawer, List } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/root-reducer";
import { toggleBurgerMenu } from "../../../redux/global/global";
import { useStyles as useStyleDesktop } from "./DesktopNavigation.styles";
import { Menu } from "@material-ui/icons";
import useStyles from "./MobileNavigation.styles";
import { gaEventClickCddRegistration } from "../../../utils/ga";
import { selectUser, selectUserInit, selectUserName } from "../../../redux/user/selectors";
import BadgeUnreadMsgs from "./BadgeUnreadMsgs";
import BadgeFollowedContracts from "./BadgeFollowedContracts";
import { logOutAction } from "../../../redux/user/actions";
import PuffLoader from "react-spinners/PuffLoader";
import { COLORS } from "../../../theme/theme";
import { useHandleLogin } from "../../../hooks/useHandleLogin";

const MobileNavigation: React.FC = () => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const history = useHistory();
  const { global: { burgerMenuOpen } } = useSelector((state: RootState) => state);
  const userName = useSelector(selectUserName);
  const user = useSelector(selectUser);
  const isInit = useSelector(selectUserInit);

  const closemenu = () => {
    dispatch(toggleBurgerMenu());
  };

  const { handleLogin } = useHandleLogin()

  const handleLogOut = () => {
    dispatch(logOutAction());
    history.push("/");
  };

  const handleRegister = () => gaEventClickCddRegistration();

  const classesDesktop = useStyleDesktop();
  const classes = useStyles();
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ position: "absolute", right: 20 }}
        startIcon={<Menu fontSize="large" />}
        onClick={() => dispatch(toggleBurgerMenu())}
      >
        Menu
      </Button>

      <Drawer
        anchor="right"
        open={burgerMenuOpen}
        className={classes.draw}
        onClose={() => dispatch(toggleBurgerMenu())}
      >
        <nav>
          <Box mx={2} my={5} flexDirection="column" display="flex">
            <Link to="/" title="Úvodní strana">
              <Button className={classesDesktop.item}>Úvodní strana</Button>
            </Link>
            <Link to="/verejne-zakazky" onClick={closemenu} title="Katalog veřejných zakázek">
              <Button className={classesDesktop.item}>Zakázky</Button>
            </Link>
            <Link to="/zadavatele-verejnych-zakazek" onClick={closemenu} title="Zadavatelé veřejných zakázek">
              <Button className={classesDesktop.item}>Zadavatelé</Button>
            </Link>

            <Link to="/sledovane-zakazky" onClick={closemenu} title="Sledované zakázky">
              <Button
                className={classesDesktop.item}
                endIcon={<BadgeFollowedContracts />}>
                Sledované zakázky
              </Button>
            </Link>
            <Link to="/filtry-a-notifikace" onClick={closemenu} title="Filtry a notifikace">
              <Button className={classesDesktop.item}>Filtry a notifikace</Button>
            </Link>
            <Link to="/cenik" onClick={closemenu} title="Ceník">
              <Button className={classesDesktop.item}>Ceník</Button>
            </Link>
            <Link to="/ekomunikace" title="eKomunikace">
              <Button
                className={classesDesktop.item}
                endIcon={<BadgeUnreadMsgs />}
              >
                eKomunikace
              </Button>
            </Link>
            {!isInit ? <span style={{ marginLeft: 20 }}><PuffLoader color={COLORS.RED} size={30} /></span> :
              (!user ? (
                <>
                  <div>
                    <Button onClick={handleLogin} id="prihlasit" className={classesDesktop.itemProfile}>
                      Přihlásit
                    </Button>
                  </div>
                  <a onClick={handleRegister} href={`${process.env.REACT_APP_CDD_URL}/#/registrace`}>
                    <Button className={classesDesktop.itemProfile}>Nová registrace</Button>
                  </a>
                </>
              ) : (
                <>
                  <Button className={classesDesktop.item} onClick={() => setIsCollapsed(!isCollapsed)}>
                    {userName}
                  </Button>
                  <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ textAlign: "center" }}>
                      <Button className={classesDesktop.item} onClick={() => history.push("/profil")} fullWidth>
                        Můj účet
                      </Button>
                      <Divider />
                      <Button className={classesDesktop.item} onClick={() => handleLogOut()} fullWidth>
                        Odhlásit se
                      </Button>
                    </List>
                  </Collapse>
                </>
              ))
            }
          </Box>
        </nav>
      </Drawer>
    </>
  );
};

export default MobileNavigation;
