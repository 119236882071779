import { createStyles, makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../theme/theme";

const RouteStyles = makeStyles(() =>
  createStyles({
    lockedButton: {
      border: `1px solid ${COLORS.GOLD} !important`,
      color: `${COLORS.GOLD} !important`,
      maxWidth: 200,
      margin: "0 auto"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: "100%",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      flex: "1 1 auto",
      padding: theme.spacing(2, 4, 3)
    },
    card: {
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    },
    textTitle: {
      fontWeight: 800,
      color: COLORS.GOLD,
      marginBottom: 20
    },
    textBody: {
      color: "#707070"
    },
    button: {
      fontWeight: 500,
      marginTop: 60,
      backgroundColor: COLORS.GOLD,
      margin: "10px 10px 30px 10px",
      "&:hover": {
        backgroundColor: "#b19b81"
      }
    }
  })
);

export default RouteStyles;
