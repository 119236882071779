import { Button, Modal, Paper, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { COLORS } from "../../../theme/theme";
import ConfirmationModalStyles from "./ConfirmationModal.styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  subtitle: string | React.ReactNode;
  icon?: IconProp;
  confirmText: string;
  onClose: () => void;
  confirmationAction: () => void;
  cancelAction: () => void;
  confirmationOnly?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  confirmationAction,
  cancelAction,
  title,
  subtitle,
  icon,
  confirmText,
  confirmationOnly = false
}) => {
  const classes = ConfirmationModalStyles();
  return (
    <Modal id="modal-confirm" onClose={onClose} open={isOpen}>
      <Paper className={classes.paper}>
        <div className={classes.typographyContainer}>
          {icon && <FontAwesomeIcon className={classes.icon} icon={icon} size="3x" color={COLORS.RED} />}
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </div>
        <div className={classes.actionButtonContainer}>
          {!confirmationOnly && (
            <Button variant="outlined" onClick={cancelAction} className={classes.cancelActionButton} disableElevation>
              Zrušit
            </Button>
          )}
          <Button
            color="primary"
            onClick={confirmationAction}
            variant="contained"
            className={classes.confirmActionButton}
            disableElevation
          >
            {confirmText}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default ConfirmationModal;
