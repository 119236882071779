import React from "react";
import { Grid } from "@material-ui/core";
import ContractCardPlaceholder from "../ContractCard/ContractCard.placeholder";

interface ContractListPlaceholderProps {
  itemCount?: number;
}

const ContractListPlaceholder: React.FC<ContractListPlaceholderProps> = ({ itemCount }) => (
  <Grid container style={{ flexGrow: 1 }}>
    {Array.from(new Array(itemCount || 9)).map((contract: undefined, index: number) => {
      return <ContractCardPlaceholder key={`contractPlaceholder${index + 1}`} />;
    })}
  </Grid>
);

export default ContractListPlaceholder;
