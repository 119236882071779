import { createStyles, makeStyles } from "@material-ui/core/styles";

const RibbonStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      padding: "15px 0 5px 0",
      textAlign: "center",
      width: 142,
      transform: "rotate(45deg)",
      position: "absolute",
      boxShadow: "0 1px 1px #a9a9a9, 0 -1px 1px #a9a9a9"
    },
    label: {
      position: "absolute",
      textTransform: "uppercase",
      fontWeight: 700,
      transform: "rotate(1deg)"
    }
  })
);

export default RibbonStyles;
