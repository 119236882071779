import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { COLORS, theme } from "../../theme/theme";
import ScrollToTop from "react-scroll-to-top";
import Header from "../Layout/Header/Header";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Footer from "../Layout/Footer/Footer";
import { RootState } from "../../redux/root-reducer";
import AppStyles from "./App.styles";
import AutoScroll from "../AutoScroll/AutoScroll";
import { ToastContainer, Zoom } from "react-toastify";
import "../../assets/toastify.css";
import { useKeycloak } from "@react-keycloak/web";
import { logIn, logOutAction } from "../../redux/user/actions";
import { history } from "../../utils/window";
import renderRoutes from "../../navigation/renderRoutes";
import routes from "../../navigation/routes";
import { gaEventAfterCddLogin, gaSetPage, gaSetUserData } from "../../utils/ga";
import CookiePanel from "../CookiePanel/CookiePanel";
import CaptchaWrap from "../Captcha/CaptchaWrap";
import { clearUserStatus, locationChangeUser, setUserInit } from "../../redux/user/user";
import ConfirmationModal from "../Modals/Confirmation/ConfirmationModal";
import { locationChangeFilters } from "../../redux/filters/filters";
import { removeQueryParamToUrl } from "../../utils/url";
import { useHandleLogin } from "../../hooks/useHandleLogin";
import PodoApi from "../../api/PodoApi";

document.title = "Všechny veřejné zakázky na jednom místě | Portál Dodavatele";

const App: React.FC = () => {
  const styles = AppStyles();
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const { handleLogin } = useHandleLogin();

  const userId = useSelector((state: RootState) => state.user.user?.id);
  const [showCookie, setShowCookie] = useState(history.location.pathname === "/");

  useEffect(() => {
    history.listen((location) => {
      dispatch(locationChangeUser());
      dispatch(locationChangeFilters());
      gaSetPage(location.pathname + location.search);
      setShowCookie(location.pathname === "/");
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (userId) {
      PodoApi.getGAUserData()
        .then(userData => {
          gaSetUserData(userData);
          if (urlParams.get("loginByUser")) {
            gaEventAfterCddLogin();
            removeQueryParamToUrl("loginByUser");
          }
        });
    } else {
      gaSetUserData({ userId: null, userProperties: [] });
    }
  }, [userId]);

  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated && keycloak.token) {
        dispatch(logIn());
      } else {
        dispatch(setUserInit());
      }
    }
  }, [initialized]);

  const [userSessionExpiredModalOpen, setUserSessionExpiredModalOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (!!params.get("relogin")) { // TODO je pouzito?
      dispatch(clearUserStatus());
    }

    if (!!params.get("logoutCDD")) {
      dispatch(logOutAction("Centrální odhlášení z CDD"));
      history.push("/");
    }

    if (!!params.get("logoutNoRefresh")) {
      setUserSessionExpiredModalOpen(true);
      removeQueryParamToUrl("logoutNoRefresh");
    }

  }, []);


  return (
    <ErrorBoundary>
      <Router history={history}>
        <div className={styles.wrap}>
          <Header />
          <AutoScroll />
          <ToastContainer position={"top-right"} autoClose={10000} transition={Zoom} draggablePercent={100} />
          <CaptchaWrap>
            <div className={styles.root}>{renderRoutes(routes)}</div>
          </CaptchaWrap>
          <ScrollToTop
            smooth
            color={theme.palette.primary.main}
            style={{
              backgroundColor: "rgba(0,0,0,.2)",
              boxShadow: "0 0px 14px 0 rgb(132 128 177 / 28%)",
              display: "flex",
              alignItems: "center"
            }}
          />
          <Footer />
        </div>
        <ConfirmationModal
          isOpen={userSessionExpiredModalOpen}
          title={"Vítejte zpět na Portálu dodavatele. Proběhlo automatické odhlášení."}
          subtitle={
            <div>
              Můžete se opět{" "}
              <span
                style={{ color: COLORS.GOLD, cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  setUserSessionExpiredModalOpen(false);
                  handleLogin();
                }}
              >
                přihlásit
              </span>{" "}
              prostřednictvím Centrální databáze dodavatelů (CDD).
            </div>
          }
          confirmText={"Zavřít"}
          onClose={() => setUserSessionExpiredModalOpen(false)}
          confirmationAction={() => setUserSessionExpiredModalOpen(false)}
          cancelAction={() => setUserSessionExpiredModalOpen(false)}
          confirmationOnly
        />
      </Router>
      {showCookie &&
        <CookiePanel />
      }
    </ErrorBoundary>
  );
};

export default App;
