import { Button, Chip, TextField } from "@material-ui/core";
import * as lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IContract, Tag } from "../../typings/contract";
import { currentString } from "../../utils/string";
import ContractList from "./components/ContractList";
import TagList from "./components/TagList";
import { ContractResearcherStyles } from "./ContractSearcher.container";
import ContractSearcherStyles from "./ContractSearcher.styles";
import { gaEventSelectTag } from "../../utils/ga";
import PodoApi from "../../api/PodoApi";

interface ContractSearcherProps {
  tags: Array<Tag>;
  value?: string;
  tagValue?: Array<string>;
  tagsLoading: boolean;
  customStyle?: ContractResearcherStyles;
  withSubmitButton: boolean;
  withContractSearch: boolean;
  fullWidth?: boolean;
  onInputChange?: (phrase: string) => void;
  onTagsChange?: (tags: Array<string>) => void;
  searchContractsOnEmptyInput?: boolean;
  maxTagAmount?: number;
}
const MIN_LEN = 2
const ContractSearcher: React.FC<ContractSearcherProps> = ({
  value,
  tagValue,
  tags,
  tagsLoading,
  customStyle,
  fullWidth = false,
  onInputChange,
  onTagsChange,
  withSubmitButton,
  withContractSearch,
  searchContractsOnEmptyInput = true,
  maxTagAmount = 3
}) => {
  const history = useHistory();
  const classes = ContractSearcherStyles();
  const searchInputRef = useRef<HTMLInputElement>();
  const [focusedListItem, setFocusedListItem] = useState<number>(0);
  const [isLoading] = useState<boolean>(tagsLoading);
  const [lastApiValue, setLastApiValue] = useState<{ phrase: string; tags: Array<string> } | null>(null);
  const [selectedTags, setSelectedTags] = useState<Array<string>>(tagValue || []);
  const [inputValue, setInputValue] = useState<string>(value || "");
  const [contracts, setContracts] = useState<Array<IContract>>([]);
  const [isContractLoading, setIsContractLoading] = useState<boolean>(false);
  const [listHovered, setListHovered] = useState<boolean>(false);
  const [contractListOpened, setContractListOpened] = useState<boolean>(false);
  const [invalidTags, setInvalidTags] = useState<Array<string>>([]);
  const [isSearchingTag, setIsSearchingTag] = useState<boolean>(false);
  const [searchedTagPhrase, setSearchedTagPhrase] = useState<string>("");
  const [focusedPosition, setFocusedPosition] = useState<number | null>(null);
  const [searchInputFocused, setSearchInputFocused] = useState<boolean>(false);
  const [containsInvalidTags, setContainsInvalidTags] = useState<boolean>(false);
  const [shortPhrase, setShortPhrase] = useState<boolean>(false);


  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value || "");
    }
    if (tagValue) {
      setSelectedTags(tagValue);
    }
  }, [value, tagValue]);

  const fetchContractsByPhrase = (phrase: string, tags: Array<string>) => {
    const filteredPhrase = phrase
      .split(" ")
      .filter((word) => !word.startsWith("#"))
      .join(" ")
      .trimLeft()
      .trimRight();
    setIsContractLoading(true);
    setContracts([]);
    const fullPhrase = filteredPhrase.length >= MIN_LEN ? filteredPhrase : "";
    if (lastApiValue?.phrase === fullPhrase && lastApiValue.tags === tags) return null;

    setLastApiValue({ phrase: fullPhrase, tags });

    if (phrase.length >= MIN_LEN || tags.length > 0) {
      PodoApi.fetchContractSuggest(fullPhrase, tags)
        .then(data => {
          setContracts(data);
        })
        .finally(() => {
          setIsContractLoading(false);
        });
    }
  };

  const onChange = (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | React.MouseEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const currentValue = event.currentTarget.value;
    const lastStringPosition = currentValue.length - 1;
    const cursorPosition = event.currentTarget.selectionStart;
    const lastWrittenSymbolCode = currentValue.charCodeAt(lastStringPosition);
    const focused = currentString(currentValue, cursorPosition || 0);
    setFocusedPosition(cursorPosition);

    if (lastWrittenSymbolCode === 35 || (focused.startsWith("#") && !isSearchingTag)) {
      setIsSearchingTag(true);
      setContractListOpened(false);
    }

    if (lastWrittenSymbolCode === 32 || !focused.startsWith("#")) {
      setIsSearchingTag(false);
      setSearchedTagPhrase("");
    }

    if (isSearchingTag || focused.startsWith("#")) {
      const focusedWithoutHash = focused.slice(1, focused.length);
      setSearchedTagPhrase(focusedWithoutHash);
    }

    setShortPhrase(false);
    setContractListOpened(false);
    if (withContractSearch && !isSearchingTag) {
      if (currentValue.length >= MIN_LEN || (currentValue.length === 0 && selectedTags.length)) {
        const debounce = lodash.debounce(() => {
          if (inputValue !== currentValue) {
            setIsContractLoading(true);
            fetchContractsByPhrase(currentValue, selectedTags);
          }
          setContractListOpened(true);
        }, 0);
        debounce();
      } else if (currentValue.length < MIN_LEN && currentValue.length > 0) {
        setShortPhrase(true);
        setContractListOpened(true);
      } else {
        setContractListOpened(false);
      }
    }

    setInputValue(currentValue);
    onTagsChange && onTagsChange(selectedTags);
    onInputChange && onInputChange(currentValue);
  };

  const selectTag = (index: number) => {
    const tagCode = tagsFiltered[index].code;
    gaEventSelectTag(tagCode);
    const focused = currentString(inputValue, focusedPosition || 0);
    const regExp = new RegExp(`(${focused}) |${focused}$`, "g");
    const replaced = inputValue.replace(regExp, "");
    setInputValue(replaced);
    onInputChange && onInputChange(replaced);
    if (maxTagAmount <= selectedTags.length) {
      setIsSearchingTag(false);
      return toast.error(`Můžete zadat maximálně ${maxTagAmount} TAGy`);
    }
    const newTags = [...selectedTags, tagCode];
    setSelectedTags(newTags);
    setIsSearchingTag(false);

    onTagsChange && onTagsChange(newTags);
    if (withContractSearch && newTags.length > 0) {
      fetchContractsByPhrase(inputValue, newTags);
      setContractListOpened(true);
    }
  };
  const onTagClick = (index: number) => {
    selectTag(index);
    setSearchedTagPhrase("");
    searchInputRef.current?.focus();
    searchInputRef.current?.setSelectionRange(focusedPosition, focusedPosition);
  };

  const deleteTag = (index: number) => {
    const newTags = [...selectedTags];
    newTags.splice(index, 1);
    setSelectedTags([...newTags]);

    if (withContractSearch) {
      fetchContractsByPhrase(inputValue, selectedTags);
      setContractListOpened(true);
    }

    onTagsChange && onTagsChange(newTags);
  };

  const checkForInvalidTags = () => {
    const tagsInInput = Array.from(inputValue.matchAll(/#([a-z0-9A-Z]+)/g)).map((a) => a[1]);
    const codesOfExistingTags = tags.map((tag) => tag.code);
    const invalidTags = tagsInInput.filter((tag) => !codesOfExistingTags.includes(tag));

    if (Boolean(invalidTags.length)) {
      setContainsInvalidTags(true);
      setInvalidTags(invalidTags);
    } else {
      setContainsInvalidTags(false);
      setInvalidTags([]);
    }

    setIsSearchingTag(false);
    setSearchedTagPhrase("");
  };

  const tagsFiltered = tags.filter(({ code, label }) =>
    !selectedTags.includes(code) &&
    (code.toLowerCase().includes(searchedTagPhrase as string) || label.toLowerCase().includes(searchedTagPhrase as string))
  ).sort((a, b) => a.label.localeCompare(b.label));

  const handleKeyDown = (key: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (contractListOpened || isSearchingTag) key?.stopPropagation();
    if (key?.key === "ArrowDown") {
      let position = 0;
      if (contractListOpened) position = focusedListItem + 1 > contracts.length - 1 ? 0 : focusedListItem + 1;
      if (isSearchingTag) position = focusedListItem + 1 > tagsFiltered.length - 1 ? 0 : focusedListItem + 1;
      (contractListOpened || isSearchingTag) && setFocusedListItem(position);
    }
    if (key?.key === "ArrowUp") {
      let position = 0;
      if (contractListOpened) position = focusedListItem - 1 < 0 ? contracts.length - 1 : focusedListItem - 1;
      if (isSearchingTag) position = focusedListItem - 1 < 0 ? tagsFiltered.length - 1 : focusedListItem - 1;
      (contractListOpened || isSearchingTag) && setFocusedListItem(position);
    }
    if (key?.key === "Backspace") {
      if (inputValue.length === 0) {
        if (selectedTags.length) {
          deleteTag(selectedTags.length - 1);
        }
      }
    }
    if (key?.key === "Enter") {
      if (!contractListOpened && !isSearchingTag && inputValue.length >= MIN_LEN && searchContractsOnEmptyInput)
        findContracts();
      if (contractListOpened && !isSearchingTag) {
        if (contracts.length === 0) return findContracts();
        const slug = contracts[focusedListItem].slug;
        history.push(`/verejne-zakazky/${slug}`);
      }

      if (isSearchingTag && tagsFiltered.length) {
        key?.preventDefault();
        selectTag(focusedListItem);
      }
    }
  };

  const findContracts = () => {
    history.push(
      `/verejne-zakazky?phrase=${encodeURIComponent(
        `${inputValue
          .trimEnd()
          .split(" ")
          .filter((word) => !word.startsWith("#"))
          .join(" ")}${selectedTags.length > 0 ? " " : ""}${selectedTags.map((tag) => `#${tag}`).join(" ")}`
      )}`
    );
  };

  useEffect(() => {
    if (!isSearchingTag) checkForInvalidTags();
  }, [isSearchingTag]);

  return (
    <React.Fragment>
      <div className={customStyle?.wrapper || classes.wrapper}>
        <TextField
          variant="outlined"
          value={inputValue}
          placeholder="Název zakázky, název nebo zkratka zadavatele, popis, nebo #TAG"
          inputRef={searchInputRef}
          onFocus={() => setSearchInputFocused(true)}
          onChange={(event) => onChange(event)}
          onKeyDown={handleKeyDown}
          onBlur={() => {
            if (!listHovered) checkForInvalidTags();
            setSearchInputFocused(false);
          }}
          InputProps={{
            className: customStyle?.input || classes.input,
            startAdornment: (
              <div className={classes.tagWrapper}>
                {selectedTags.map((tag, index) => (
                  <Chip key={index} label={`#${tag}`} color="primary" clickable onDelete={() => deleteTag(index)} />
                ))}
              </div>
            )
          }}
          inputProps={{ className: customStyle?.innerInput || classes.innerInput, onClick: (event) => onChange(event) }}
          fullWidth={fullWidth}
        />
        {withSubmitButton && (
          <Button
            variant="contained"
            onClick={findContracts}
            className={customStyle?.searchButton || classes.searchButton}
          >
            Najít zakázku
          </Button>
        )}
        {!isSearchingTag &&
        (searchInputFocused || listHovered) &&
        (inputValue.length >= MIN_LEN || selectedTags.length > 0 || shortPhrase) &&
        contractListOpened ? (
          <ContractList
            contracts={contracts}
            isLoading={isContractLoading}
            onMouseEnter={() => setListHovered(true)}
            onMouseLeave={() => setListHovered(false)}
            activeItemIndex={focusedListItem}
            onListClose={() => setFocusedListItem(0)}
            listStyle={customStyle?.popUpList || classes.popUpList}
            shortPhrase={shortPhrase}
          />
        ) : (
          ""
        )}
        {!isLoading && isSearchingTag && (
          <TagList
            tags={tagsFiltered}
            onMouseEnter={() => setListHovered(true)}
            onMouseLeave={() => setListHovered(false)}
            activeItemIndex={focusedListItem}
            onListClose={() => setFocusedListItem(0)}
            listStyle={customStyle?.popUpList || classes.popUpList}
            onTagSelect={onTagClick}
          />
        )}
      </div>
      {!isSearchingTag && containsInvalidTags && invalidTags.length && (
        <div style={{ width: "100%" }}>Pozor, hledáte tagy, které neexistují: {invalidTags.join(", ")}</div>
      )}
    </React.Fragment>
  );
};

export default ContractSearcher;
