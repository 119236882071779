import { makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../../theme/theme";

export const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: COLORS.LIGHTGREY,
    "&::after": {
      display: "block",
      content: '" "',
      width: "100%",
      height: 8,
      opacity: 0.25,
      background: COLORS.RED
    },
    "& a": {
      textDecoration: "none"
    }
  },
  logoLink: {
    color: COLORS.BLUE,
    textTransform: "uppercase",
    fontWeight: 800
  },
  logo: {
    width: 155,
    [theme.breakpoints.down("xs")]: {
      width: 120
    }
  },
  logoFen:{
    height: 35,
    marginTop: 5,
  },
  maintenanceInfo: {
    position: "absolute",
    top: 57,
    left: 0,
    right: 0,
    zIndex: 1,
    background: "#F88379",
    textAlign: "center",
    fontWeight: 800,
    color: "#33597b",
    fontSize: "1.2rem",
    "& a": {
      color: "inherit !important",
      textDecoration: "underline"
    }
  },
  useMobileAppInfo: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 2,
    top: 57,
    background: "#F88379",
    textAlign: "center",
    color: "#33597b",
    fontSize: "1.2rem",
    padding: "5px 25px",
    "& a": {
      fontWeight: 800,
      textDecoration: "underline",
      color: "#33597b!important"
    },
    "& span": {
      position: "absolute",
      right: 0,
      top: 0,
      fontSize: "2rem",
      lineHeight: "2rem",
      padding: "0 5px"
    }
  }
}));

export default useStyles;
