import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { NEWFILTER } from "../filters/filters";
import * as lodash from "lodash";
import { IFilter } from "../../typings/filters";

export interface IStatistics {
  frequency: {
    [x: string]: number;
  };
  overall: {
    contracts: number;
    moneyAmount: number;
    submitters: number;
    activeContracts: number;
    contractsLastMonth: number;
    contractsLastYear: number;
    contractsRequestsCount: number;
    contractsEmailNotificationsCount: number;
    contractsPushNotificationsCount: number;
    submittersRequestsCount: number;
  };
}

interface GlobalState {
  searchedPhrase: string;
  burgerMenuOpen: boolean;
  visibleFilter: IFilter;
  visibleFilterCreated: boolean;
  statistics: {
    data: IStatistics | null;
    loading: boolean;
    error: boolean;
  };
  secureBlock: boolean
}

const INITIAL_STATE: GlobalState = {
  searchedPhrase: "",
  burgerMenuOpen: false,
  visibleFilter: NEWFILTER,
  visibleFilterCreated: false,
  statistics: {
    data: null,
    loading: true,
    error: false
  },
  secureBlock: false
};

const globalSlice = createSlice({
  name: "global",
  initialState: INITIAL_STATE,
  reducers: {
    setPhrase: (state, action) => {
      state.searchedPhrase = action.payload;
    },
    toggleBurgerMenu: (state) => {
      state.burgerMenuOpen = !state.burgerMenuOpen;
    },
    changeFilter: (state, action) => {
      state.visibleFilter = {
        ...state.visibleFilter,
        ...action.payload
      };
    },
    setFilter: (state, action) => {
      state.visibleFilter = lodash.mergeWith(state.visibleFilter, action.payload, (objValue, srcValue) => {
        if (lodash.isArray(objValue)) {
          return srcValue;
        }
      });
    },
    clearFilter: (state) => {
      state.visibleFilter = NEWFILTER;
      state.visibleFilterCreated = false;
    },
    fetchStatisticsStart: (state: Draft<GlobalState>) => {
      state.statistics.loading = true;
    },
    fetchStatisticsSuccess: (state: Draft<GlobalState>, action: PayloadAction<IStatistics>) => {
      state.statistics.loading = false;
      state.statistics.data = action.payload;
    },
    fetchStatisticsFailure: (state: Draft<GlobalState>) => {
      state.statistics.loading = false;
      state.statistics.error = true;
    },
    setVisibleFilterCreated: (state, action: PayloadAction<boolean>) => {
      state.visibleFilterCreated = action.payload;
    },
    setSecureBlock:(state, action: PayloadAction<boolean>)=>{
      state.secureBlock = action.payload
    }
  }
});

const { actions, reducer } = globalSlice;
export const {
  setPhrase,
  toggleBurgerMenu,
  changeFilter,
  setFilter,
  clearFilter,
  fetchStatisticsStart,
  fetchStatisticsSuccess,
  fetchStatisticsFailure,
  setVisibleFilterCreated,
  setSecureBlock
} = actions;
export default reducer;
