import { filterExists, selectVisibleFilter, selectVisibleFilterWithoutId } from "../global/selectors";
import selectPage, { selectContracts, selectRowsPerPage, selectSort } from "./selectors";
import { isFormEmpty, transformFilterValuesForCrud } from "../../utils/filter";
import { selectUser } from "../user/selectors";
import {
  applyFilter,
  fetchContractsFailure,
  fetchContractsStart,
  fetchContractsSuccess,
  saveAndApplyFilter
} from "./contractCatalogue";
import { addPageToContractCollection } from "../../utils/contract";
import { parseErrorSync } from "../../utils/api";
import { AppThunk } from "../store";
import { toast } from "react-toastify";
import { scrollIntoElementViewByClass } from "../../utils/window";
import { gaEventFilterAdd, gaEventFilterUpdate } from "../../utils/ga";
import { saveFilterSuccess } from "../filters/filters";
import { changeFilter, setVisibleFilterCreated } from "../global/global";
import { fetchFilters } from "../filters/actions";
import PodoApi from "../../api/PodoApi";
import { IFilterParams } from "../../typings/filters";
import * as lodash from "lodash";

export const fetchContracts = (omit?: boolean): AppThunk => async (dispatch, getState) => {
  dispatch(fetchContractsStart({ omit: omit }));
  const visibleFilter = selectVisibleFilter(getState());
  const page = selectPage(getState());
  const sort = selectSort(getState());
  const rowsPerPage = selectRowsPerPage(getState());
  const contracts = selectContracts(getState());
  const transformedFilter = transformFilterValuesForCrud(visibleFilter);
  const user = selectUser(getState());

  try {
    const isFull = !!visibleFilter.full;
    if (!isFormEmpty(visibleFilter as any) && user) {
      const filterParams = transformedFilter.currentVersion.params;
      const { data, results } = await PodoApi.fetchContractsSearch(filterParams, page, rowsPerPage, sort, isFull);
      dispatch(fetchContractsSuccess({
        contracts: data,
        results: results,
        omit: omit
      }));
    } else {
      if (visibleFilter.phrase?.length) {
        const filterParams: IFilterParams = { phrase: visibleFilter.phrase };
        const { data, results } = await PodoApi.fetchContractsSearch(filterParams, page, rowsPerPage, sort, isFull);
        dispatch(fetchContractsSuccess({
            contracts: addPageToContractCollection(data, page),
            results: results,
            omit: omit
          })
        );
      } else {
        const { data, results } = await PodoApi.getContracts(page, rowsPerPage, sort);
        dispatch(fetchContractsSuccess({
            contracts: addPageToContractCollection(data, page),
            results: results,
            omit: omit
          })
        );
      }
    }
  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(fetchContractsFailure(message));
    toast.error(message);
  }

  if (page > 1) {
    if (omit) {
      scrollIntoElementViewByClass("contract-card");
    } else {
      scrollIntoElementViewByClass("contract-card", contracts.length);
    }
  }
};

export const applyFilterAction = (omit?: boolean): AppThunk => async (dispatch, getState) => {
  dispatch(applyFilter({ omit: omit }));
  const filter = selectVisibleFilterWithoutId(getState());
  const sort = selectSort(getState());
  const page = selectPage(getState());
  const contracts = selectContracts(getState());
  const rowsPerPage = selectRowsPerPage(getState());
  const transformedFilter = transformFilterValuesForCrud(filter);

  try {
    const isFull = !!filter.full;
    const filterParams = transformedFilter.currentVersion.params;
    const { data, results } = await PodoApi.fetchContractsSearch(filterParams, page, rowsPerPage, sort, isFull);
    dispatch(fetchContractsSuccess({
        contracts: addPageToContractCollection(data, page),
        results: results,
        omit: omit
      })
    );
  } catch (e: unknown) {
    const message = parseErrorSync(e);
    dispatch(fetchContractsFailure(message));
    toast.error(message);
  }

  if (page > 1) {
    if (omit) {
      scrollIntoElementViewByClass("contract-card");
    } else {
      scrollIntoElementViewByClass("contract-card", contracts.length);
    }
  }
};

export const saveAndApplyFilterAction = (omit?: boolean): AppThunk => async (dispatch, getState) => {
  const filter = selectVisibleFilter(getState());
  const filterData = lodash.omit(filter, "id")
  const valid = await PodoApi.validateFilterParams(filterData)
    .then(res => {
      if (!res.valid) {
        toast.error(`Filtr není validní. Prosím zkontrolujte jeho nastavení, především klíčová slova.`);
      } else {
        return true;
      }
    }).catch(e => {
      const message = parseErrorSync(e, `Nepodařilo se provést validaci filtru.`);
      toast.error(message);
    });

  if (!valid) {
    return;
  }

  dispatch(saveAndApplyFilter({ omit: omit }));
  const exists = filterExists(getState());
  const page = selectPage(getState());
  const contracts = selectContracts(getState());
  const rowsPerPage = selectRowsPerPage(getState());

  try {
    const transformedFilter = transformFilterValuesForCrud(filter, !exists);
    if (!exists) {
      const data = await PodoApi.createUserFilter(transformedFilter);
      gaEventFilterAdd();
      dispatch(saveFilterSuccess());
      dispatch(changeFilter({ id: data.id }));
      dispatch(setVisibleFilterCreated(true));
      toast(`Filtr úspěšně vytvořen.`, { type: "success" });

    } else {
      await PodoApi.updateUserFilter(filter.id || "", transformedFilter);
      gaEventFilterUpdate();
      dispatch(saveFilterSuccess());
      toast("Filtr úspěšně upraven.", { type: "success" });
    }

    dispatch(fetchFilters());

    const newFilter = selectVisibleFilter(getState());

    const transformedFilterContracts = transformFilterValuesForCrud(newFilter);
    const filterParams = transformedFilterContracts.currentVersion.params;

    const { data, results } = await PodoApi.fetchContractsForFilter(filterParams, page, rowsPerPage);

    dispatch(fetchContractsSuccess({
        contracts: addPageToContractCollection(data, page),
        results: results,
        omit: omit
      })
    );
  } catch (e: any) {
    const message = parseErrorSync(e);
    dispatch(fetchContractsFailure(message));
    toast.error(message);
  }

  if (page > 1) {
    if (omit) {
      scrollIntoElementViewByClass("contract-card");
    } else {
      scrollIntoElementViewByClass("contract-card", contracts.length);
    }
  }
};
