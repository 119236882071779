import React, { useEffect } from "react";
import { Button, Grid, TextField, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import feedbackFormSchema, { feedbackFormSchemaType } from "./schema";
import FeedbackFormStyles from "./FeedbackForm.styles";
import SendIcon from "@material-ui/icons/Send";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/root-reducer";
import { sendFeedback } from "../../../../../redux/profile/actions";

const FeedbackForm: React.FC = () => {
  const classes = FeedbackFormStyles();
  const dispatch = useDispatch();
  const { success } = useSelector((state: RootState) => state.profile.status);
  const { user } = useSelector((state: RootState) => state.user);
  const { handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(feedbackFormSchema),
    mode: "onBlur",
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (success) {
      reset({
        copyToAuthor: false,
        feedback: ""
      });
    }
    // eslint-disable-next-line
  }, [success]);

  const submitForm = (data: feedbackFormSchemaType) => dispatch(sendFeedback(data));

  return (
    <React.Fragment>
      <Typography variant={"body1"} className={classes.text}>
        Pokud máte jakékoli připomínky k fungování portálu{" "}
        <a href="https://podo.fen.cz/" target="_blank" rel="noopener noreferrer" title="PoDo.cz">
          PoDo.cz
        </a>
        , napříkad jste objevili zakázku, která není v našem systému, prosím, pošlete nám na ni odkaz. Pro tento účel
        můžete využít kontaktního formuláře. Naši specialisté se v nejbližší možné době k vašemu požadavku vyjádří.
      </Typography>
      <form onSubmit={handleSubmit((data) => submitForm(data as feedbackFormSchemaType))}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name={"feedback"}
              control={control}
              defaultValue={""}
              render={({ onChange, onBlur, name, value }) => (
                <TextField
                  value={value}
                  onBlur={onBlur}
                  name={name}
                  variant="outlined"
                  label="Text*"
                  onChange={onChange}
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.feedback}
                  helperText={errors.feedback?.message}
                  InputProps={{
                    className: classes.input,
                    value: value || ""
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"copyToAuthor"}
              control={control}
              defaultValue={false}
              render={({ onChange, onBlur, name, value }) => (
                <FormControlLabel
                  control={<Checkbox color={"primary"} checked={value} />}
                  onChange={(event, checked) => onChange(checked)}
                  checked={value}
                  value={value}
                  onBlur={onBlur}
                  name={name}
                  label={`Přeposlat kopii zpětné vazby na ${user?.email}`}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button startIcon={<SendIcon />} type="submit" variant="contained" color="primary">
              Odeslat
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default FeedbackForm;
