import { IContract } from "../../typings/contract";
import { useExternalSystems } from "@qcm/ekom-lib";
import { useSelector } from "react-redux";
import { selectUserCanEkom, selectUserRoles } from "../../redux/user/selectors";

interface iHasEkomWrap {
  contract: IContract;
  children: React.ReactNode;
  minUserRole?: string;
}

const HasEkomWrap = ({ contract, children, minUserRole }: iHasEkomWrap) => {

  const userCanEkom = useSelector(selectUserCanEkom);
  const userRoles = useSelector(selectUserRoles);

  const { data: extSystemWithEkom } = useExternalSystems();
  const extSys = contract.externalSystem?.url;
  const extId = contract.externalIdentifier;
  const hasEkom = extId && extSys && extSystemWithEkom.includes(extSys) && (!minUserRole || userRoles.includes(minUserRole));

  if (!userCanEkom || !hasEkom) {
    return null;
  }

  return <>{children}</>;
};
export default HasEkomWrap;
