import { makeStyles } from "@material-ui/core/styles";
import {COLORS, theme} from "../../theme/theme";

const StatisticsStyles = makeStyles(() => ({
  midWrap: {
    background: COLORS.BGBLUE,
    padding: "70px 10%"
  },
  midTitle: {
    fontSize: 28,
    fontWeight: 300,
    paddingBottom: 40
  },
  subtitle: {
    marginBlockEnd: 5
  },
  chartWrapper: {
    width: "100%",
    margin: "0 auto",
    marginBottom: 35
  },
  chartSectionWrapper: {
    maxWidth: 1280,
    margin: "0 auto",
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr",
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "3fr 1fr"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr"
    }
  },
  chartSectionWrapperWide: {
    maxWidth: 1280,
    margin: "0 auto",
    display: "grid",
    gridTemplateColumns: "1fr 3fr 1fr",
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "3fr 1fr"
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr"
    }
  },
  cardWrapper: {
    maxWidth: 1280,
    margin: "0 auto"
  },
  onlyLarge: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-end",
    textAlign: "center",
    columnGap: "0.5rem",
    marginBottom: "1rem"
  },
  card: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 180,
    background: "#0C1C27",
    color: "#AA8E6C",
    overflow: "inherit"
  },
  cardLight: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 180,
    background: "#e0e5e9",
    color: "#AA8E6C",
    overflow: "inherit"
  },
  cardContent: {
    height: 76,
    width: 76,
    margin: "-40px auto 0",
    border: "6px solid #0c1c27",
    borderRadius: "50%",
    color: "#AA8E6C",
    background: "#0c1c27"
  },
  cardContentLight: {
    height: 56,
    width: 76,
    margin: "-20px auto 0",
    border: "6px solid #e0e5e9",
    borderRadius: "50%",
    color: "#AA8E6C",
    background: "#e0e5e9"
  },
  text: {
    height: 60,
    color: "#AA8E6C",
    fontWeight: 700,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "baseline",
    textAlign: "center",
    columnGap: "0.5rem"
  },
  textMin:{
    fontSize: 32,
    height: 45,
    marginTop: 10,
  },
  descWrapper: {
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  catalogLink: {
    float: "right",
    color: "white",
    textDecoration: "underline",
    fontSize: 15
  }
}));

export default StatisticsStyles;
