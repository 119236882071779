import { feedbackFormSchemaType } from "../../pages/Profile/Overview/components/FeedbackForm/schema";
import { clearStatus, sendFeedbackFailure, sendFeedbackStart, sendFeedbackSuccess } from "./profile";
import { toast } from "react-toastify";
import { AppThunk } from "../store";
import { parseErrorSync } from "../../utils/api";
import PodoApi from "../../api/PodoApi";


export const sendFeedback = (data: feedbackFormSchemaType): AppThunk => async (dispatch, getState) => {
  dispatch(sendFeedbackStart());
  try {
    if (data) {
      await PodoApi.sendUserFeedback(data.feedback, data.copyToAuthor);
      dispatch(sendFeedbackSuccess());
      toast(`Zpětná vazba byla zaslána. Děkujeme.`, {
        type: "success",
        onOpen: () => dispatch(clearStatus())
      });
    }
  } catch (e) {
    const message = parseErrorSync(e);
    dispatch(sendFeedbackFailure(message));
    toast.error(message, {
      onClose: () => dispatch(clearStatus())
    });
  }
};