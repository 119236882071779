import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../theme/theme";

export const useStyles = makeStyles((theme) => ({
  menu: {
    marginLeft: 30
  },
  item: {
    color: COLORS.RED,
    fontWeight: "bold",
    // marginLeft: 4,
    // marginRight: 4,
    textTransform: "none"
  },
  itemProfile: {
    color: COLORS.BLUE,
    fontWeight: "bold",
    marginLeft: 5,
    textTransform: "none"
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  userMenuHeader: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    alignItems: "center"
  },
  menuLink: {
    textDecoration: "none"
  },
  menuItem: {
    fontSize: 14
  }
}));

export default useStyles;
