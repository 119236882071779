import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortField, SortOrder } from "../../enum/sort";
import { IContract } from "../../typings/contract";

interface IState {
  contracts: {
    data: Array<IContract>;
    results: number;
  };
  filterApplied: boolean;
  sort: {
    field: SortField;
    order: SortOrder;
  };
  rowsPerPage: 20 | 50 | 100;
  status: {
    omit: boolean;
    loading: boolean;
    error: boolean;
    message: string;
  };
  prefillFilterModalOpen: boolean;
  paidFeatureModalOpen: boolean;
  page: number;
}

const INITIAL_STATE: IState = {
  contracts: {
    data: [],
    results: 0
  },
  filterApplied: false,
  sort: {
    field: SortField.BEGINS,
    order: SortOrder.DESC
  },
  rowsPerPage: 20,
  status: {
    omit: false,
    loading: false,
    error: false,
    message: ""
  },
  prefillFilterModalOpen: false,
  paidFeatureModalOpen: false,
  page: 1
};

const catalogueSlice = createSlice({
  name: "contractCatalogue",
  initialState: INITIAL_STATE,
  reducers: {
    fetchContractsSuccess: (state, action: PayloadAction<{ contracts: IContract[], results: number, omit?: boolean }>) => {
      const { contracts, results } = action.payload;

      if (state.page > 1 && !action.payload?.omit) {
        state.contracts.data = state.contracts.data.concat(contracts);
      } else {
        state.contracts.data = contracts;
      }
      state.contracts.results = Math.min(results, 10000); // omezeni max polozek z elasticsearch
      state.status.loading = false;
      state.status.omit = false;
    },
    fetchContractsFailure: (state, action) => {
      const msg = action.payload;
      state.status.omit = false;
      state.status.loading = false;
      state.status.error = true;
      state.status.message = msg;
    },
    fetchContractsStart: (state, action: PayloadAction<{ omit?: boolean }>) => {
      state.status.loading = true;
      state.status.omit = action.payload?.omit || false;
    },
    applyFilter: (state, action: PayloadAction<{ omit?: boolean }>) => {
      state.status.loading = true;
      state.filterApplied = true;
      state.status.omit = action.payload?.omit || false;
    },
    saveAndApplyFilter: (state, action: PayloadAction<{ omit?: boolean }>) => {
      state.status.loading = true;
      state.filterApplied = true;
      state.status.omit = action.payload?.omit || false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    togglePaidModal: (state) => {
      state.paidFeatureModalOpen = !state.paidFeatureModalOpen;
    },
    setSortField: (state, action) => {
      state.sort.field = action.payload;
    },
    setSortOrder: (state, action) => {
      state.sort.order = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    resetFilter: (state) => {
      state.filterApplied = false;
    },
    clearContracts: (state) => {
      state.contracts.data = [];
      state.contracts.results = 0;
    },
    clearSort: (state) => {
      state.sort = {
        field: SortField.BEGINS,
        order: SortOrder.DESC
      };
    }
  }
});

const { actions, reducer } = catalogueSlice;
export const {
  fetchContractsStart,
  setPage,
  fetchContractsSuccess,
  fetchContractsFailure,
  setSortField,
  setSortOrder,
  setRowsPerPage,
  togglePaidModal,
  applyFilter,
  saveAndApplyFilter,
  resetFilter,
  clearContracts,
  clearSort
} = actions;
export default reducer;
