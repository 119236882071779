import { faArrowCircleRight, faTags } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { Box, Button, Container, Grid, Tooltip } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ContractList from "../../components/ContractList/ContractList";
import ContractSearcherContainer from "../../components/ContractSearcher/ContractSearcher.container";
import img1 from "../../assets/img1.webp";
import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import img2 from "../../assets/img2.webp";
import img3 from "../../assets/img3.webp";
import { setFilter, setPhrase } from "../../redux/global/global";
import CategoryPicker from "./components/CategoryPicker/CategoryPicker";
import useStyles from "./Home.styles";
import Faq from "./components/Faq/Faq";
import Statistics from "./components/Statistics/Statistics";
import { HelpOutlineRounded } from "@material-ui/icons";
import BasicSearchTooltip from "../../components/Tooltip/BasicSearchTooltip";
import { useDispatch, useSelector } from "react-redux";
import ContractListPlaceholder from "../../components/ContractList/ContractList.placeholder";
import { RootState } from "../../redux/root-reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchContracts } from "../../redux/contractCatalogue/actions";
import { selectUserInit } from "../../redux/user/selectors";
import NewsList from "../../components/News/NewsList";
// import { INITIAL_STATE } from "../../redux/submitterCatalogue/submitterCatalogue";
// import { setRowsPerPage } from "../../redux/contractCatalogue/contractCatalogue";

const CONTRACT_ROWS_NUM = 5;

const Home: React.FC = () => {
  const classes = useStyles();
  const params = useLocation();
  const dispatch = useDispatch();
  const { contracts, status } = useSelector((state: RootState) => state.contractCatalogue);
  const isInit = useSelector(selectUserInit);

  useEffect(() => {
    if (isInit) {
      // Set rows per page
      // dispatch(setRowsPerPage(CONTRACT_ROWS_NUM));
      dispatch(fetchContracts());
      // Set rows per page back to initial
      // dispatch(setRowsPerPage(INITIAL_STATE.rowsPerPage));
    }
  }, [isInit]);

  useEffect(() => {
    if (params.hash) {
      const id = params.hash.replace("#", "");

      document.getElementById(id)?.scrollIntoView();
    }
  });

  return (
    <div className={classes.hpWrap}>
      <div className={classes.topWrap}>
        <Container maxWidth="lg">
          <div className={classes.introBlock}>
            <div className={classes.intro1}>Portál dodavatele</div>
            <h1 className={classes.intro2}>Všechny veřejné zakázky na jednom místě</h1>
            <div className={classes.intro3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              Najděte si tu správnou
              <Tooltip title={<BasicSearchTooltip />} enterTouchDelay={50} leaveTouchDelay={10000} interactive arrow>
                <HelpOutlineRounded style={{ color: "rgb(118, 118, 118)", marginLeft: 5 }} />
              </Tooltip>
            </div>
          </div>

          <div className={classes.introWrapper}>
            <div className={classes.introSectionWrapper}>
              <div className={classes.introTitle}>
                <FontAwesomeIcon className={classes.introTitleIcon} icon={faKeyboard} /> Vyhledávání s našeptávačem
              </div>
              <span className={classes.introSubTitle}>Napište normální text...</span>
            </div>
            <div className={classes.introSectionWrapper}>
              <div className={classes.introTitle}>
                <FontAwesomeIcon className={classes.introTitleIcon} icon={faTags} /> Hledat podle TAGů zakázky
              </div>
              <span className={classes.introSubTitle}>Začněte tímto symbolem: #</span>
            </div>
            <div className={classes.introLinkWrap}>
              <FontAwesomeIcon className={classes.introTitleIcon} icon={faArrowCircleRight} />
              <Link to="/verejne-zakazky" title="Katalog veřejných zakázek">
                <span className={classes.introLink}>Pokročilé filtrování v Katalogu zakázek</span>
              </Link>
            </div>
          </div>
          <ContractSearcherContainer
            onInputChange={(phrase) => {
              const phraseSplit = phrase.split(" ");
              const tags = phraseSplit
                .filter((string) => string.startsWith("#"))
                .map((string) => string.replace("#", ""));
              const words = phraseSplit
                .filter((string) => !string.startsWith("#"))
                .join(" ")
                .trimStart()
                .trimEnd();
              dispatch(setFilter({ phrase: words, tags: tags }));
              dispatch(setPhrase(words));
            }}
          />
          <CategoryPicker />
        </Container>
      </div>

      <Statistics />

      <div className={classes.bottomWrap}>
        <Container maxWidth="lg">
          <div className={classes.newsWrap}>
            <h2 className={classes.hpTitle}>Nejnovější zakázky</h2>
            {(status.loading && !contracts.data.length) || !isInit ? (
              <ContractListPlaceholder />
            ) : (
              <ContractList contracts={contracts.data.slice(0, CONTRACT_ROWS_NUM)} />
            )}
            <div className={classes.moreContractsWrapper}>
              <Link to="/verejne-zakazky" title="Katalog veřejných zakázek">
                <Button color={"primary"} variant={"contained"} className={classes.moreContractsButton}>
                  Více
                </Button>
              </Link>
            </div>
          </div>
        </Container>

        <div className={classes.hpNews}>
          <Container maxWidth="lg">
            <h2 className={classes.hpTitle}>Aktuality</h2>
            <NewsList limit={3} />
            <div className={classes.moreContractsWrapper}>
              <Link to={"/aktuality"} title={"Další aktuality"}>
                <Button color={"primary"} variant={"contained"} className={classes.moreContractsButton}>
                  Další aktuality
                </Button>
              </Link>
            </div>
          </Container>
        </div>

        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={6}>
              <Box className={classes.centerBox}>
                <div className={classes.hpListTitle}>Pokročilé vyhledávání vždy najde ty správné zakázky pro Vás</div>
                <div className={classes.hpListText}>
                  Zakázky můžete hledat podle mnoha kritérií. Náš našeptávač s podporou umělé inteligence vám poradí,
                  jak se dobrat ke správné zakázce.
                </div>
              </Box>
            </Grid>
            <Grid item md={6}>
              <div className={classes.hpBoxImg}>
                <img className={classes.hpListImg} src={img1} width="100%" height="100%" alt="hp img" />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} className={classes.mobileOrder}>
              <div className={classes.hpBoxImg}>
                <img className={classes.hpListImg} style={{ marginLeft: 85 }} src={img2} width="100%" height="100%"
                     alt="hp img" />
              </div>
            </Grid>
            <Grid item md={6}>
              <Box className={classes.centerBox}>
                <div className={classes.hpListTitle}>Díky inteligentním filtrům Vám neunikne žádná zakázka</div>
                <p className={classes.hpListText}>
                  Určete, jaké zakázky chcete sledovat a systém bude 24 x 7 podle nich pečlivě a důsledně vyhledávat
                  všechny zakázky.
                </p>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6}>
              <Box className={classes.centerBox}>
                <div className={classes.hpListTitle}>Informace o zakázkách dříve než ostatní</div>
                <p className={classes.hpListText}>
                  Buďte napřed před konkurencí, dostávejte zprávy o zakázkách ihned, jak se objeví, nebo podle určeného
                  rozvrhu.
                </p>
              </Box>
            </Grid>
            <Grid item md={6}>
              <div className={classes.hpBoxImg}>
                <img className={classes.hpListImg} src={img3} width="100%" height="100%" alt="hp img" />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} className={classes.mobileOrder}>
              <div className={classes.hpBoxImg}>
                <img className={classes.hpListImg} src={img3} width="100%" height="100%" alt="hp img" />
              </div>
            </Grid>
            <Grid item md={6}>
              <Box className={classes.centerBox}>
                <div className={classes.hpListTitle}>Sledujte život vybraných zakázek do posledního detailu</div>
                <p className={classes.hpListText}>Nechejte si posílat informace o každé změně u vybraných zakázek.</p>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg">
          <Faq />
        </Container>
      </div>
    </div>
  );
};

export default Home;
