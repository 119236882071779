import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/root-reducer";
import { useKeycloakActions } from "../../../../../hooks/useKeycloakActions";
import { tokensClear } from "../../../../../utils/tokens";
import LineInfo from "../LineInfo";

interface CddIdentityProps {
  cddInformation: any;
}

const CddIdentity: React.FC<CddIdentityProps> = ({ cddInformation }) => {
  const { keycloakLogout } = useKeycloakActions();
  const { user } = useSelector((state: RootState) => state.user);

  const handleCddLogout = () => {
    tokensClear();
    keycloakLogout(window.location.href + "?logoutCDD=true");
  };

  return (
    <Grid container spacing={1} alignItems="baseline">

      <Grid item container sm={6} spacing={1}>
        <LineInfo label="Jméno" text={`${cddInformation?.user?.firstName} ${cddInformation?.user?.lastName}`} />
        <LineInfo label="E-mail" text={cddInformation?.user?.email} />
        <LineInfo label="CDD ID uživatele" text={cddInformation?.user?.cddId} />
      </Grid>

      <Grid item container sm={6} spacing={1}>
        <LineInfo label="Organizace" text={cddInformation?.supplier?.name} />
        <LineInfo label="IČO" text={cddInformation?.supplier?.ic} />
        <LineInfo label="CDD ID organizace" text={cddInformation?.supplier?.cddId} />
      </Grid>

      <Grid item xs={12}>
        <p style={{ paddingTop: 30 }}>
          Administrace uživatelských účtů a údajů organizace se provádí v Centrální databázi dodavatelů (CDD).
        </p>
      </Grid>
      <Grid item xs={"auto"}>
        <a href={`${process.env.REACT_APP_CDD_URL}/#/uzivatel/${user?.cddId}`} title="CDD profil">
          <Button variant="outlined">Otevřít CDD profil</Button>
        </a>
      </Grid>
      <Grid item xs={"auto"}>
        <Button variant="outlined" onClick={handleCddLogout}>
          Centrální CDD odhlášení
        </Button>
      </Grid>
    </Grid>
  );
};

export default CddIdentity;
