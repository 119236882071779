import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { usePageViewMeta } from "../../hooks/usePageViewMeta";
import PageWrap from "../../components/PageWrap/PageWrap";
import NewsList from "../../components/News/NewsList";

const NewsListPage = () => {
  const { setPageMeta } = usePageViewMeta();
  useEffect(() => {
    setPageMeta(
      "Aktuality | Portál Dodavatele",
      "Sledujte nejnovější aktuality a novinky ze světa veřejných zakázek. Získejte aktuální informace, trendy a tipy, které vám pomohou uspět ve vašem podnikání."
    );
  }, []);


  return (
    <PageWrap
      breadcrumbNavigation={[{ label: "Aktuality", path: "/aktuality" }]}
      title="Aktuality"
    >

      <Container maxWidth="lg">
        <NewsList limit={18} showPaginator />
      </Container>
    </PageWrap>
  );
};

export default NewsListPage;
