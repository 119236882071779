interface IWeight {
  free: number;
  profi: number;
  premium: number;
  excellent: number
}

const roleWeights: IWeight = {
  free: 1,
  profi: 2,
  premium: 3,
  excellent: 4
};

export const getRoleWeight: (role: string) => number = (role: string) => roleWeights[role as keyof IWeight];

export const findLowestAllowedRole: (allowedRoleArray: Array<string>) => number = (allowedRoleArray: Array<string>) =>
  Math.min(...allowedRoleArray.map((roleName: string) => getRoleWeight(roleName)));
