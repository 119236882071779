import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Accordion from "../../../../components/Accordion/Accordion";
import useStyles from "../../Home.styles";

const Faq: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.faqWrap} id="faq">
      <h2 className={classes.hpTitle}>FAQ</h2>
      <Accordion
        title="V čem a proč je Portál Dodavatele na trhu jedinečný?"
        description={() => (
          <div className={classes.text}>
            <p>
              Portál Dodavatele je unikátní službou, která agreguje (shromažďuje) veřejné zakázky <u>ze všech</u>{" "}
              certifikovaných elektronických nástrojů a tak dokáže spolehlivě nalézt každému Dodavateli jemu
              odpovídající zakázky. Vždy úplně, přesně a včas. Je to díky přímému napojení na systémy zadavatelů, přes
              které vypisují veřejné zakázky. Navíc poskytuje řadu pokročilých funkcionalit, které Dodavateli šetří čas
              a minimalizují riziko pochybení při účasti v soutěži o zakázku.
            </p>
            <p>
              Společnost Spojené nástroje elektronické, s.r.o. – provozovatel Portálu Dodavatele — jako společný podnik
              QCM s.r.o. a Tender Systems s.r.o., je leader v elektronizaci veřejných zakázek, poskytuje elektronické
              nástroje E-ZAK, Tender Arena, Portál pro vhodné uveřejnění, Tender Market, elektronické tržiště Gemin a
              podílí se na vývoji a provozu systémů FEN, ROZZA a VVZ. Lze tak říci, že informace o VZ jsou dodavateli
              poskytovány přímo „ze zdroje“.
            </p>
          </div>
        )}
      />
      <Accordion
        title="Dá se používat Portál Dodavatele zdarma?"
        description={() => (
          <div className={classes.text}>
            <p>
              Ano. Pro základní využití portálu postačuje registrace. Systém podporuje i Centrální databázi dodavatelů —
              pokud jste někdy pracovali s nějakým elektronickým nástrojem na zadávání veřejných zakázek, pravděpodobně
              již máte účet. V případě potřeby uděláte novou registraci
              <a
                href="https://www.fen.cz/#/registrace"
                target="_blank"
                rel="noopener noreferrer"
                title="Registrovat se"
              >
                {` zde`}
              </a>
              .
            </p>
          </div>
        )}
      />
      <Accordion
        title="Jaké jsou výhody placené verze?"
        description={() => (
          <p>
            Placená verze umožní nejen podrobnější vyhledávání zakázek, ale hlavně rychlé a přesné upozorňování na
            zakázky, které vyhovují vašim požadavkům. Kritéria výběru zakázek si můžete libovolně nastavovat tak, abyste
            dostávali vždy nejrelevantnější informace.
          </p>
        )}
      />
      <Accordion
        title="Jaké zakázky vám budeme posílat?"
        description={() => (
          <p>
            Budeme vám zasílat informace o nových zakázkách dle nastavených kritérií. Filtr vám umožňuje definovat
            zdroje zakázek, klíčová slova, CPV kódy, IČO zadavatelů či hlavní místa plnění. Můžete si nastavit až 10
            různých takovýchto filtrů. Informace o nových zakázkách vám dojdou souhrnně jednou denně za předchozí den,
            nebo si můžete výsledky filtru zobrazit okamžitě.
          </p>
        )}
      />
      <Accordion
        title="Jakým způsobem je možné služby objednat?"
        description={() => (
          <div className={classes.text}>
            <p>
              Placené služby Portálu Dodavatele primárně fungují na bázi měsíčního předplatného. Po zadání platební
              karty se vám bude automaticky strhávat z karty příslušná částka, dokud službu nezrušíte.
            </p>
            <p>
              Služby je rovněž možné objednat „na fakturu“, kdy je vystavena zálohová faktura, kterou je nutno uhradit
              bankovním převodem. Tento postup je možný pouze pro zakoupení služeb na celý rok.
            </p>
          </div>
        )}
      />
      <Accordion
        title="Jak zruším službu?"
        description={() => (
          <p>
            Pokud naše služby nechcete již dále využívat, můžete službu zrušit. Zrušení bude provedeno ke konci
            aktuálního měsíce, tedy již nestrhneme žádné další peníze z karty, a od nového měsíce můžete na Portálu
            Dodavatele používat jen neplacené služby. Roční předplatné „na fakturu“ v průběhu období stornovat nelze.
            Pokud byste se rozhodli placený přístup obnovit, nastavení vám zůstanou zachována.
          </p>
        )}
      />
      <Accordion
        title="Jaké zakázky se na Portálu Dodavatele vyhledávají a na co lze nastavit upozornění?"
        description={() => (
          <div className={classes.text}>
            <p>
              Portál Dodavatele je určen zejména pro uživatele - dodavatele, kteří chtějí mít komplexní a rychlý přehled
              o všech zakázkách, které byly vypsány zadavateli podle zákona o veřejných zakázkách a to včetně zakázek
              malého rozsahu.
            </p>
            <p>
              Možnost vyfiltrovat pouze AKTIVNÍ zakázky, na které lze stále podat nabídku či žádost o účast, nabízí
              služba Dodavatel Premium jako jednu ze svých pokročilých funkcí.
            </p>
            <p>
              Upozorňování na změny u zakázek zařazených do sledovaných zakázek uživatele se týká pouze těch aktivních a
              to v rozsahu jejich evidovaných metadat (změna v názvu, popisu, předpokládané hodnotě, termínech - lhůtách
              apod.) a metadat jejich dokumentů (přidání či změna dokumentu zakázky), je-li tato změna uveřejněna na
              Profilu zadavatele. Jelikož na Portále nejsou evidováni dodavatelé ani výsledky zakázek, nelze upozorňovat
              na tyto změny - Premium uživatelé však mají k dispozici odkaz na zdrojový systém zakázky i u již
              NEAKTIVNÍCH zakázek.
            </p>
          </div>
        )}
      />
      <Accordion
        title="Je potřeba ověřovat registraci na CDD?"
        description={() => (
          <p>
            Pro účely základního přístupu k PoDo není nezbytné ověření (dokončení) vaší registrace dodavatele v CDD,
            nicméně pro zobrazení si detailu zakázky na některých elektronických nástrojích (a stejně tak pro účast ve
            výběrových řízeních – podání elektronické nabídky, žádosti o účast apod.) budete ověření registrace
            (identity) v CDD potřebovat a je tudíž doporučeno si ji vyřídit s předstihem.
          </p>
        )}
      />
      <Accordion
        title="Je možné pod jedním profilem dodavatele zaregistrovat více uživatelů?"
        description={() => (
          <p>
            PoDo k ověření identity využívá účty výhradně z CDD. Pokud je potřeba více uživatelů v PoDo, musí si
            uživatel nejprve založit svůj účet ve své organizaci v CDD (fen.cz).
          </p>
        )}
      />
      <div className={classes.tosWrapper}>
        <Link to="/vseobecne-obchodni-podminky" className={classes.tosButton} title="VOP">
          <Button variant="contained" color="primary">
            Všeobecné obchodní podmínky
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Faq;
