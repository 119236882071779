import React from "react";
import { Badge } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../theme/theme";
import { useSelector } from "react-redux";
import { selectFollowedContracts } from "../../../redux/user/selectors";


const BadgeFollowedContracts = () => {
  const followedContracts = useSelector(selectFollowedContracts);

  return (
    <Badge badgeContent={followedContracts?.length} color="primary" showZero={false}>
      <FontAwesomeIcon icon={faEye} color={COLORS.RED} />
    </Badge>
  );
};

export default BadgeFollowedContracts;