import { createStyles, makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../../theme/theme";

const useStyles = makeStyles(() =>
  createStyles({
    errorContainer: {
      height: "100%",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    errorMessage: {
      minWidth: "20%",
      maxWidth: "50%"
    },
    strip: {
      borderTop: "5px solid lightgrey",
      padding: "40px 0"
    },
    stripBottom: {
      borderBottom: "5px solid lightgrey"
    },
    actionButton: {
      marginTop: 20
    },
    detailTitle: {
      textAlign: "left",
      fontWeight: 800
    },
    detailData: {
      "& *": {
        wordBreak: "break-word"
      }
    },
    cancelSubscription: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        marginTop: 50
      }
    },
    historyTitle: {
      fontWeight: 800,
      borderBottom: "2px solid #9b9b9b",
      marginBottom: 10
    },
    subtitle: {
      fontWeight: 800,
      color: COLORS.BLUE,
      marginBottom: 40,
      fontSize: 18
    },
    input: {
      background: "white",
      borderRadius: 12,
      border: "1px solid #e2e2e2",
      position: "absolute"
    },
    status: {
      textAlign: "center",
      color: COLORS.BLUE,
      fontWeight: 800
    },
    historyWrap: {
      maxWidth: 800,
      margin: "0 auto"
    },
    p: {
      margin: "5px 0 0 0",
      paddingLeft: 4
    }
  })
);

export default useStyles;
