import React, { useEffect } from "react";
import { Grow } from "@material-ui/core";
import ContractCard from "../ContractCard/ContractCard";
import { IContract, IContractWithPage } from "../../typings/contract";
import { makeStyles } from "@material-ui/styles";
import { useMsgsCountAdd } from "@qcm/ekom-lib";
import InfoBox from "../InfoBox/InfoBox";

interface ContractListProps {
  noContractsText?: string;
  contracts: Array<IContract | IContractWithPage>;
}

const useStyles = makeStyles({
  list: {
    listStyle: "none",
    paddingLeft: 0
  }
});

const ContractList: React.FC<ContractListProps> = ({ contracts, noContractsText }) => {

  const { addToFetch } = useMsgsCountAdd();

  useEffect(() => {
    if (contracts) {
      addToFetch(contracts.map(d => ({
        extObjId: d.externalIdentifier,
        extSystem: d.externalSystem?.url || ""
      })));
    }
  }, [contracts]);

  const classes = useStyles();
  return (
    <ul className={classes.list}>
      {contracts.length === 0 && (
        <InfoBox text={noContractsText || "Nebyly nalezeny žádné zakázky"} />
      )}
      {contracts.map((contract: IContract) => (
        <Grow in key={contract.id} timeout={200}>
          <ContractCard key={contract.id} contract={contract} />
        </Grow>
      ))}
    </ul>
  );
};

export default ContractList;
