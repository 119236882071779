import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    date: {
      display: "block", marginTop: -40
    },
    content: {
      margin: "50px 0 80px 0",
      "& p, & ul": {
        margin: 0
      },
      "& img": {
        maxWidth: "100%",
        height: "auto",
        margin: "10px 0"
      }
    }
  })
);

export default useStyles;
