import { createTheme } from "@material-ui/core/styles";

export const COLORS = {
  RED: "#C73B3B",
  BLUE: "#16496E",
  GOLD: "#AA8E6C",
  GOLD2: "#D4AF37",
  BRONZE: "#cd7f32",
  SILVER: "#C0C0C0",
  LIGHTGREY: "#F6F4F2",
  BGBLUE: "#122A3B",
  BGBLUEDARK: "#0C1C27",
  GREY: "#707070",
  BLACK: "#000000",
  BGGREY: "#F7F7F7",
  BGBLUELIGHT: "#e0e5e9"
};

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.RED
    },
    secondary: {
      main: COLORS.BLUE
    },
    background: {
      default: "red"
    }
  },
  typography: {
    h1: {
      color: "#16496E"
    },
    h2: {
      color: "#16496E"
    },
    h3: {
      color: "#16496E"
    },
    h4: {
      color: "#16496E"
    },
    fontFamily: "Montserrat,sans-serif"
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          background: "$labelcolor"
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
        maxWidth: 600,
        padding: '10px 10px 5px 10px'
      }
    }
  }
});
