import React, { useCallback, useEffect, useState } from "react";
import { iNews, iNewsReqParams } from "../../typings/news";
import NewsApi from "../../api/NewsApi";
import InfoBox from "../InfoBox/InfoBox";
import LoadingBox from "../LoadingBox/LoadingBox";
import { Grid } from "@material-ui/core";
import { format } from "date-fns";
import { cs } from "date-fns/locale";
import { Link } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

interface iNewsList {
  limit: number;
  showPaginator?: boolean;
}

const NewsList = ({ limit, showPaginator = false }: iNewsList) => {

  const [data, setData] = useState<iNews>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [page, setPage] = useState(1);
  const [totalCount, setTotlaCount] = useState(0);
  const pagesCount = Math.ceil(totalCount / limit);

  const nextPageFromPagination = (e: any, page: number) => {
    fetchData(page);
  };

  const fetchData = useCallback((page: number) => {
    setPage(page);
    setLoading(true);
    const params: iNewsReqParams = {
      pageSize: limit,
      page: page,
      sortPublishedAsc: false
    };
    NewsApi.getNews(params)
      .then(({ data, results }) => {
        setData(data);
        setTotlaCount(results);
      })
      .catch(e => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData(1);
  }, []);

  if (error) {
    return <InfoBox text={"Chyba načtení aktualit"} />;
  }

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <>
      <Grid container spacing={3}>
        {data?.slice(0, limit).map((item, index) =>
          <Grid key={index} item md={4}>
            <article>
              <h3>
                <Link to={`/aktuality/${item.Slug}`} title={item.Title}>
                  {item.Title}
                </Link>
              </h3>
              <small>
                {format(new Date(item.PublishedUtc), "dd. MM. yyyy HH:mm", { locale: cs })}
              </small>
              <div dangerouslySetInnerHTML={{ __html: item.Perex ?? "" }} />
            </article>
          </Grid>
        )}
      </Grid>
      {showPaginator &&
        <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
          <Pagination page={page} onChange={nextPageFromPagination} count={pagesCount} />
        </div>
      }
    </>
  );
};


export default NewsList;
