import { Button, IconButton, makeStyles } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { usePagination, UsePaginationProps } from "@material-ui/lab";
import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

const useStyles = makeStyles({
  nav: {
    margin: "0 10px"
  },
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center"
  },
  ellipsis: {
    height: "auto",
    margin: "0 3px"
  },
  paginationItem: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  button: {
    minWidth: 32,
    height: 32,
    padding: "0 10px",
    margin: "0 3px"
  },
  buttonSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  },
  buttonNextPrev: {
    padding: "0 6px",
    borderRadius: 4
  }
});

const Pagination: React.FC<UsePaginationProps> = (props) => {
  const { items } = usePagination(props);
  const classes = useStyles();
  const queryParams = queryString.parse(window.location.search);

  const BtnPage = ({ page, selected, disabled }: { page: number, selected: boolean, disabled: boolean }) =>
    <Button
      className={`${classes.button} ${classes.paginationItem} ${selected ? classes.buttonSelected : ""}`}
      variant={"outlined"}
      disabled={selected || disabled}
    >
      {page}
    </Button>;

  const BtnNext = ({ disabled }: { disabled: boolean }) =>
    <IconButton
      disableRipple
      disabled={disabled}
      className={`${classes.button} ${classes.paginationItem} ${classes.buttonNextPrev}`}
    >
      <KeyboardArrowRight />
    </IconButton>;

  const BtnPrev = ({ disabled }: { disabled: boolean }) =>
    <IconButton
      disableRipple
      disabled={disabled}
      className={`${classes.button} ${classes.paginationItem} ${classes.buttonNextPrev}`}
    >
      <KeyboardArrowLeft />
    </IconButton>;

  return (
    <nav className={classes.nav}>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (page > 1) {
            queryParams.page = page;

            delete queryParams.scrollToFirst;
          } else {
            delete queryParams.page;

            queryParams.scrollToFirst = true;
          }

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = <div className={classes.ellipsis}>…</div>;
          } else if (type === "page") {
            children = !item.disabled && !selected ? (
              <Link onClick={item.onClick} to={`?${queryString.stringify(queryParams)}`} rel="next">
                <BtnPage page={page} selected={selected} disabled={item.disabled} />
              </Link>
            ) : (
              <BtnPage page={page} selected={selected} disabled={item.disabled} />
            );
          } else if (type === "next") {
            children = !item.disabled ? (
              <Link onClick={item.onClick} to={`?${queryString.stringify(queryParams)}`} rel="next">
                <BtnNext disabled={item.disabled} />
              </Link>
            ) : (
              <BtnNext disabled={item.disabled} />
            );
          } else if (type === "previous") {
            children = !item.disabled ? (
              <Link onClick={item.onClick} to={`?${queryString.stringify(queryParams)}`} rel="prev">
                <BtnPrev disabled={item.disabled} />
              </Link>
            ) : (
              <BtnPrev disabled={item.disabled} />
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
