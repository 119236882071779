import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/root-reducer";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import { cs } from "date-fns/locale";
import useStyles from "../../Overview.styles";
import { Button } from "@material-ui/core";
import { isSubsPlanFree } from "../../../../../typings/subscriptionPlan";

const Subscription: React.FC = () => {
  const classes = useStyles();
  const { user } = useSelector((state: RootState) => state.user);

  return (
    <Grid container item spacing={1} xs={12} sm={10} lg={12} alignItems="center">
      <Grid item lg={3} sm={4} xs={12}>
        <div className={classes.detailTitle}>Plán předplatného</div>
      </Grid>
      <Grid item lg={3} sm={8} xs={12}>
        {user?.activeSubscription.level.toLocaleUpperCase()}
      </Grid>
      <Grid item lg={3} sm={4} xs={12} hidden={isSubsPlanFree(user?.activeSubscription.level)}>
        <div className={classes.detailTitle}>Stav</div>
      </Grid>
      <Grid item lg={3} sm={8} xs={12} hidden={isSubsPlanFree(user?.activeSubscription.level)}>
        <Typography variant="body1">
          {user?.activeSubscription.validTo
            ? `aktivní do ${format(new Date(user.activeSubscription.validTo), "dd. MM. yyyy", { locale: cs })}`
            : `neaktivní`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <a href={`${process.env.REACT_APP_PAYMENT_URL}/#/e-shop/platby-a-objednavky`} title="E-shop">
          <Button variant={"outlined"} className={classes.actionButton}>
            Přejít do E-shopu
          </Button>
        </a>
      </Grid>
      <p className={classes.p}>Pro předplatná aktivovaná kartou po 1.11.2021 jsou faktury k dispozici v e-shopu.</p>
    </Grid>
  );
};

export default Subscription;
