import React from "react";
import { IUser } from "../../../../../typings/user";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LineInfo from "../LineInfo";

interface UserInformationProps {
  user: IUser;
}

const UserInformation: React.FC<UserInformationProps> = ({ user }) => {
  const history = useHistory();

  return (
    <Grid container spacing={1} alignItems="baseline">
      <Grid item container sm={6} spacing={1} >
        <LineInfo label="Kontaktní osoba" text={`${user.firstName} ${user.lastName}`} />
        <LineInfo label="E-mail" text={user.email} />
        <LineInfo label="Telefon" text={user.phone} />
      </Grid>

      <Grid item container sm={6} spacing={1} >
        <LineInfo label="Firma" text={user.company} />
        <LineInfo label="IČO" text={user.cin} />
        <LineInfo label="DIČ" text={user.vatin} />
        <LineInfo label="Fakturační adresa"
                  text={(user.street || user.streetNumber || user.zipCode || user.city || user.country) && (
                    <>
                      {`${user.street || ""} ${user.streetNumber || ""}`}
                      <br />
                      {`${user.zipCode || ""} ${user.city || ""}`}
                      <br />
                      {user.country || ""}
                    </>
                  )} />
      </Grid>

      <Grid item xs={12}>
        <Button variant="outlined" onClick={() => history.push("/profil/nastaveni")}>
          Upravit nastavení
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserInformation;
