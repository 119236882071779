import React from "react";
import { IContract } from "../../typings/contract";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as faEnvelopeRegular } from "@fortawesome/free-regular-svg-icons";
import { Badge, IconButton } from "@material-ui/core";
import { COLORS } from "../../theme/theme";
import { useMsgsCounts } from "@qcm/ekom-lib";
import { Link } from "react-router-dom";

interface IProps {
  contract: IContract;
}

const ContractMsgIcon = ({ contract }: IProps) => {
  const extSys = contract.externalSystem?.url || "";
  const extId = contract.externalIdentifier;
  const { data: ekomNum } = useMsgsCounts(extSys, extId);
  if (!extSys || !extId) {
    return null;
  }

  return (
    <>
      <Link to={`/verejne-zakazky/${contract.slug}#contract-msgs`} title={"eKomunikace k zakázce"}>
        <Badge badgeContent={ekomNum} color="primary" showZero={false}>
          <IconButton size={"small"} style={{}}>
            <FontAwesomeIcon icon={ekomNum ? faEnvelope : faEnvelopeRegular} color={COLORS.RED} />
          </IconButton>
        </Badge>
      </Link>
    </>
  );
};

export default ContractMsgIcon;
