import Grid from "@material-ui/core/Grid";
import React from "react";
import useStyles from "../Overview.styles";

const LineInfo = ({ label, text }: { label: string, text?: string | null | React.ReactNode }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item sm={6} xs={12} className={classes.detailTitle}>
        {label}
      </Grid>
      <Grid item  sm={6} xs={12}>
        {text}
      </Grid>
    </>
  );
};

export default LineInfo
