import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    padding: "0 0 10px 0",
    justifyContent: "center",
    width: "100%",
    "& canvas": {
      width: "100% !important",
      height: "100% !important"
    }
  }
}));

export default useStyles;
