import { ChartOptions } from "chart.js";

const config: ChartOptions = {
  scales: {
    xAxis: {
      grid: { drawOnChartArea: false }
    },
    yAxis: {
      grid: { drawOnChartArea: false }
    }
  },
  backgroundColor: "#c73b3b",
  plugins: {
    datalabels: {
      color: "#fff",
      formatter: function (value, context) {
        return parseInt(value, 10) >= 3 ? value : "";
      }
    },
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      callbacks: {
        title: function (tooltipItems) {
          return `Hodina ${tooltipItems[0].label}`;
        },
        label: function (tooltipItems) {
          if (parseInt(tooltipItems.formattedValue, 10) === 1) return `${tooltipItems.formattedValue} zakázka`;
          if (parseInt(tooltipItems.formattedValue, 10) > 1 && parseInt(tooltipItems.formattedValue, 10) < 5)
            return `${tooltipItems.formattedValue} zakázky`;

          return `${tooltipItems.formattedValue} zakázek`;
        }
      }
    }
  }
};

export default config;
