import React from "react";
import { Grid, Paper, Tooltip } from "@material-ui/core";
import { differenceInHours, format } from "date-fns";
import { cs } from "date-fns/locale";
import { useSelector } from "react-redux";
import { Rating } from "@material-ui/lab";
import useContractTags from "../../hooks/useContractTags";
import ContractWatchIcon from "../ContractWatchIcon/ContractWatchIcon";
import { IContract, IContractWithPage } from "../../typings/contract";
import useStyles from "./ContractCard.styles";
import { RootState } from "../../redux/root-reducer";
import Ribbon from "../Ribbon/Ribbon";
import { substrToNextWhitespace } from "../../utils/string";
import { HelpOutlineRounded } from "@material-ui/icons";
import { gaEventViewDetail } from "../../utils/ga";
import { Link } from "react-router-dom";
import queryString from "query-string";
import ContractMsgIcon from "../ContractMsgIcon/ContractMsgIcon";
import { useInView } from "react-intersection-observer";
import { scrollIntoElementViewByClass } from "../../utils/window";
import HasEkomWrap from "../HasEkomWrap/HasEkomWrap";
import { isSubsPlanFree } from "../../typings/subscriptionPlan";

const ContractCard: React.FC<{ contract: IContract | IContractWithPage }> = ({ contract }) => {
  const classes = useStyles();
  const { tags } = useContractTags();
  const { user } = useSelector((state: RootState) => state.user);

  const onContractClick = () => {
    const parsedQuery = queryString.parse(window.location.search);
    if ("page" in contract) {
      parsedQuery.page = contract.page;
    }

    gaEventViewDetail(contract.id, contract.name, "contract_card");
  };

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });


  return (
    <>
      <Grid ref={ref} item component={"li"} xs={12}>
        <Paper aria-label={contract.name} className={`${classes.card} contract-card`} elevation={1}>
          {differenceInHours(new Date(), new Date(contract.createAt)) <= 1 && <Ribbon />}
          {!contract.active && (
            <Ribbon labelPosition={{ top: 5, right: 43 }} fontSize=".6rem" text="Neaktivní" backgroundColor="#a9a9a9" />
          )}
          {user && !isSubsPlanFree(user.activeSubscription.level) && (
            <div className={classes.favWrap}>
              <ContractWatchIcon contract={contract} />
            </div>
          )}
          {inView && (
            <HasEkomWrap contract={contract}>
              <div className={classes.favWrapMsg}>
                <ContractMsgIcon contract={contract} />
              </div>
            </HasEkomWrap>
          )}
          <Link to={`/verejne-zakazky/${contract.slug}`} onClick={onContractClick} title={contract.name}>
            <h3 className={classes.cardTitle}>{contract.name}</h3>
          </Link>

          <div className={classes.cardRatingWrapper}>
            <Rating className={classes.cardRating} value={contract.quality / 2 / 10} readOnly />
            {contract.tags?.map((tag) => (
              <Link
                key={tag}
                to={`/verejne-zakazky?phrase=%23${tag}`}
                onClick={() => scrollIntoElementViewByClass("searchFormAnchor")}
                title={tags.find((loadedTag) => loadedTag.code === tag)?.label}
                className={classes.cardTags}
              >
                #{tag}
              </Link>
            ))}
          </div>
          <Grid container spacing={3}>
            <Grid item md={7}>
              <div className={classes.minLine}>
                <div className={classes.minTitle}>Popis:</div>
                <p className={`${classes.minText} ${classes.description}`}>
                  {contract.description ? "" : "Neuvedeno"}
                  {contract.description && contract.description.length >= 85
                    ? `${substrToNextWhitespace(contract.description, 85)}...`
                    : contract.description}
                </p>
              </div>
              <div className={classes.minLine}>
                <div className={classes.minTitle}>Zadavatel:</div>
                <div className={classes.minText}>
                  {contract.submitter.name ? "" : "Neuvedeno"}
                  <Link
                    to={`/zadavatele-verejnych-zakazek/${contract.submitter.slug}`}
                    title={`Virtuální profil zadavatele ${contract.submitter.name}`}
                    className={classes.link}
                  >
                    {contract.submitter.name && contract.submitter.name.length >= 40
                      ? `${substrToNextWhitespace(contract.submitter.name, 40)}...`
                      : contract.submitter.name}
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item md={5}>
              <div style={{ alignItems: "center" }} className={classes.minLine}>
                <div className={classes.minTitle}>Lhůta:</div>
                <div className={classes.minText}>
                  {contract.offersDeadlineAt &&
                    contract.requestsDeadlineAt &&
                    format(new Date(contract.requestsDeadlineAt), "dd. MM. yyyy HH:mm", { locale: cs })}
                  {contract.offersDeadlineAt &&
                    !contract.requestsDeadlineAt &&
                    format(new Date(contract.offersDeadlineAt), "dd. MM. yyyy HH:mm", { locale: cs })}
                  {!contract.offersDeadlineAt &&
                    contract.requestsDeadlineAt &&
                    format(new Date(contract.requestsDeadlineAt), "dd. MM. yyyy HH:mm", { locale: cs })}
                  {!contract.offersDeadlineAt && !contract.requestsDeadlineAt && "Neuvedeno"}
                </div>
                <Tooltip
                  title="Lhůta: reprezentuje indikativní datum jenž je definován jako Lhůta pro podání žádostí o účast nebo Lhůta pro podání nabídek, podle toho, který z údajů je u zakázky uveden. V případě, že jsou uvedeny oba, pak se zobrazuje dřívější, není-li k dispozici ani jedna hodnota, pak je pole prázdné."
                  arrow
                >
                  <HelpOutlineRounded style={{ color: "rgb(118, 118, 118)", marginLeft: 5, fontSize: 18 }} />
                </Tooltip>
              </div>
              <div className={classes.minLine}>
                <div className={classes.minTitle}>Datum zahájení:</div>
                <div className={classes.minText}>
                  {contract.beginsAt ? format(new Date(contract.beginsAt), "dd. MM. yyyy") : "Neuvedeno"}
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default ContractCard;
