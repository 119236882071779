import * as yup from "yup";

const feedbackFormSchema = yup.object().shape({
  copyToAuthor: yup.boolean().default(false),
  feedback: yup
    .string()
    .min(10, "Zadejte prosím minimálně 10 znaků")
    .typeError("Vyplňte prosím zpětnou vazbu")
    .required("Vyplňte prosím zpětnou vazbu")
});

export default feedbackFormSchema;

export type feedbackFormSchemaType =  ReturnType<typeof feedbackFormSchema.validateSync>