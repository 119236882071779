import React from "react";
import { RouteProps } from "../components/Route/Route";
import Home from "../pages/Home/Home.container";
import Overview from "../pages/Profile/Overview/Overview.container";
import { EKOM_LINKS } from "@qcm/ekom-lib";
import SubscriptionPlan from "../typings/subscriptionPlan";
import NewsListPage from "../pages/News/NewsListPage";
import NewsDetailPage from "../pages/News/NewsDetailPage";

const ContractCatalog = React.lazy(() => import("../pages/Contract/Catalog/Catalog.container"));
const ContractDetail = React.lazy(() => import("../pages/Contract/Detail/Detail.container"));
const FollowedContainer = React.lazy(() => import("../pages/Contract/Followed/Followed.container"));
const SubmitterCatalog = React.lazy(() => import("../pages/Submitter/Catalog/Catalog.container"));
const SubmitterDetail = React.lazy(() => import("../pages/Submitter/Detail/Detail.container"));
const ListContainer = React.lazy(() => import("../pages/Filter/List/List.container"));
const Edit = React.lazy(() => import("../pages/Filter/Edit/Edit.container"));
const Contracts = React.lazy(() => import("../pages/Filter/Contracts/Contracts.container"));
const Eshop = React.lazy(() => import("../pages/Eshop/Eshop"));
const Stats = React.lazy(() => import("../pages/Stats/Stats"));
const TermsOfSale = React.lazy(() => import("../pages/TermsOfSale/TermsOfSale"));
const Billing = React.lazy(() => import("../pages/Profile/Billing/Billing.container"));
const PaymentContainer = React.lazy(() => import("../pages/Payment/Payment.container"));
const Registration = React.lazy(() => import("../pages/Registration/Registration"));
const EkomRouter = React.lazy(() => import("../pages/Ekom/EkomRouter"));

const TEXTS = {
  FOLLOW: {
    TITLE: "Sledujte život vybraných zakázek do posledního detailu",
    TEXT: "Nechejte si posílat informace o každé změně u vybraných zakázek."
  },
  FILTER: {
    TITLE: "Získejte přehled o zakázkách, které vás zajímají!",
    TEXT: "Určete, jaké zakázky chcete sledovat a systém bude 24x7 podle nich pečlivě a důsledně vyhledávat všechny zakázky."
  }
};

const routes: Array<RouteProps> = [
  {
    path: "/",
    component: Home,
    exact: true
  },
  {
    path: "/verejne-zakazky",
    component: ContractCatalog,
    exact: true
  },
  {
    path: "/verejne-zakazky/:slug",
    component: ContractDetail,
    exact: true
  },
  {
    path: "/sledovane-zakazky",
    component: FollowedContainer,
    allowedLevels: [SubscriptionPlan.PROFI],
    notAllowedTitleText: TEXTS.FOLLOW.TITLE,
    notAllowedBodyText: TEXTS.FOLLOW.TEXT,
    loginRequired: true,
    exact: true
  },
  {
    path: "/zadavatele-verejnych-zakazek",
    component: SubmitterCatalog,
    exact: true
  },
  {
    path: "/zadavatele-verejnych-zakazek/:submitterSlug",
    component: SubmitterDetail,
    exact: true
  },
  {
    path: "/filtry-a-notifikace",
    component: ListContainer,
    allowedLevels: [SubscriptionPlan.PROFI],
    notAllowedTitleText: TEXTS.FILTER.TITLE,
    notAllowedBodyText: TEXTS.FILTER.TEXT,
    loginRequired: true,
    exact: true
  },
  {
    path: "/filtry-a-notifikace/novy-filtr",
    component: Edit,
    allowedLevels: [SubscriptionPlan.PROFI],
    notAllowedTitleText: TEXTS.FILTER.TITLE,
    notAllowedBodyText: TEXTS.FILTER.TEXT,
    loginRequired: true,
    exact: true
  },
  {
    path: "/filtry-a-notifikace/:id?",
    component: Edit,
    allowedLevels: [SubscriptionPlan.PROFI],
    notAllowedTitleText: TEXTS.FILTER.TITLE,
    notAllowedBodyText: TEXTS.FILTER.TEXT,
    loginRequired: true,
    exact: true
  },
  {
    path: "/filtry-a-notifikace/:id/verejne-zakazky",
    component: Contracts,
    allowedLevels: [SubscriptionPlan.PROFI],
    notAllowedTitleText: TEXTS.FILTER.TITLE,
    notAllowedBodyText: TEXTS.FILTER.TEXT,
    loginRequired: true,
    exact: true
  },
  {
    path: "/cenik",
    component: Eshop,
    exact: true
  },
  {
    path: "/statistiky",
    component: Stats,
    exact: true
  },
  {
    path: "/payment",
    component: PaymentContainer
  },
  {
    path: "/profil",
    component: Overview,
    loginRequired: true,
    exact: true
  },
  {
    path: "/profil/nastaveni",
    component: Billing,
    loginRequired: true,
    exact: true
  },
  {
    path: "/registrace",
    component: Registration,
    exact: true
  },
  {
    path: "/vseobecne-obchodni-podminky",
    component: TermsOfSale,
    exact: true
  },
  {
    path: "/aktuality",
    component: NewsListPage,
    exact: true
  },
  {
    path: "/aktuality/:slug",
    component: NewsDetailPage,
    exact: true
  },
  {
    path: EKOM_LINKS.base,
    component: EkomRouter,
    // notAllowedTitleText: "Mějte kompletní přehled o vaší elektronické komunikaci k zakázkám na jednom místě!",
    // notAllowedBodyText:
    //   "Získejte souhrnný seznam přijatých a odeslaných zpráv k vašim zakázkám ze všech připojených elektronických nástrojů.",
    loginRequired: true,
    exact: false
  }
];

export default routes;
