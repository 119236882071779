import React, { useState } from "react";
import useStyles from "../../Home.styles";
import CategoryPickerModalWrap from "../../../../components/CategoryPickerModal/CategoryPickerModalWrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

const CategoryPicker: React.FC = () => {
  const classes = useStyles();
  const [pickerModalOpen, setPickerModalOpen] = useState(false);
  const handleClick = () => setPickerModalOpen(true);

  return (
    <div className={classes.chooseCategoryButton}>
      <span className={classes.modalLink} onClick={handleClick}>
        <FontAwesomeIcon className={classes.modalLinkIcon} icon={faList} />
        Vyhledání zakázek podle oboru/kategorie
      </span>
      <CategoryPickerModalWrap open={pickerModalOpen} setOpen={setPickerModalOpen} />
    </div>
  );
};

export default CategoryPicker;
