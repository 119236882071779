import { IContract } from "../typings/contract";

export const addPageToContractCollection = (contracts: Array<IContract>, page: number) => {
  return contracts.map((contract) => {
    return {
      ...contract,
      page
    };
  });
};

export enum ContractState {
  "notFinished" = "zakázka neukončena",
  "supplied" = "zakázka byla zadána",
  "cancelled" = "zakázka byla zrušena",
  "finished" = "ukončeno plnění smlouvy na základě veřejné zakázky"
}

export enum ContractKind {
  DOD = "Dodávky",
  SLU = "Služby",
  STA = "Stavební práce"
}

export enum ContractExecutionPlace {
  FS = "Zahraničí",
  CZ010 = "Hlavní město Praha",
  CZ020 = "Středočeský kraj",
  CZ031 = "Jihočeský kraj",
  CZ032 = "Plzeňský kraj",
  CZ041 = "Karlovarský kraj",
  CZ042 = "Ústecký kraj",
  CZ051 = "Liberecký kraj",
  CZ052 = "Královéhradecký kraj",
  CZ053 = "Pardubický kraj",
  CZ063 = "Kraj Vysočina",
  CZ064 = "Jihomoravský kraj",
  CZ071 = "Olomoucký kraj",
  CZ072 = "Zlínský kraj",
  CZ080 = "Moravskoslezský kraj",
  CZ = "Česká republika (včetně podřízených krajů)",
  CZ_CZ0_EXACT = "Česká republika (bez podřízených krajů)"
}

export enum ProcedureKind {
  OR = "Otevřené řízení",
  UR = "Užší řízení",
  JRSU = "Jednací řízení s uveřejněním",
  JRBU = "Jednací řízení bez uveřejnění",
  ZPR = "Zjednodušené podlimitní řízení",
  VZMR = "Veřejná zakázka malého rozsahu",
  PZVZMR = "Přímé zadání - Zakázka malého rozsahu",
  PZPNR = "Přímé zadání - podlimitní/nadlimitní VZ",
  PZ = "Přímé zadání",
  OZR = "Ostatní zadávací řízení dle zákona č.137/2006 Sb.",
  SD = "Řízení se soutěžním dialogem",
  SON = "Soutěž o návrh",
  VZDNS = "VZ zadávaná v DNS",
  ROIP = "Řízení o inovačním partnerství",
  ZR = "Zjednodušený režim",
  KR = "Koncesní řízení",
  MT = "Minitendr (zadání na základě rámcové dohody)",
  NDNS = "Nákup v dynamickém nákupním systému",
  JRSUBU = "Jednací řízení s uveřejněním (bez uveřejnění) - §61 odst. 3",
  JINE = "Jiné"
}
