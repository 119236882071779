export const isValidIco = (value: string) => {
  if (value.length !== 8) return false;

  const splitValues = value.split("");
  const numberValues = splitValues.map((stringValue, index) => {
    if (index > 6) return 0;
    return parseInt(stringValue, 10) * (8 - index);
  });
  const totalValue = numberValues.reduce((accumulator, currentValue) => accumulator + currentValue);

  const a = totalValue % 11;
  let c = 11 - a;

  if (a === 1) c = 0;
  if (a === 0) c = 1;
  if (a === 10) c = 1;

  return parseInt(splitValues[7], 10) === c;
};

export const humanFileSize = (bytes: number, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};

export const getPreviousMonth = () => {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);

  const mothsCs = [
    "leden",
    "únor",
    "březen",
    "duben",
    "květen",
    "červen",
    "červenec",
    "srpen",
    "září",
    "říjen",
    "listopad",
    "prosinec"
  ];

  const lastMonth = mothsCs[d.getMonth()];
  const lastMonthYear = d.getFullYear();

  return `${lastMonth} ${lastMonthYear}`;
};
