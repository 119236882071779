import { RootState } from "../root-reducer";

const selectPage: (state: RootState) => number = (state: RootState) => state.contractCatalogue.page;

export const selectSort = (state: RootState) => state.contractCatalogue.sort;

export const selectContracts = (state: RootState) => state.contractCatalogue.contracts.data;

export const selectRowsPerPage = (state: RootState) => state.contractCatalogue.rowsPerPage;

export const selectIsLoading = (state: RootState) => state.contractCatalogue.status.loading;

export default selectPage;
