import React from "react";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { Container } from "@material-ui/core";
import { COLORS } from "../../../theme/theme";
import useStyles from "./Footer.styles";
import { Link } from "react-router-dom";

// Company logos imports
// import TenderMarket from "../../../assets/companies/TenderMarket-logo.png";
// import Ezak from "../../../assets/companies/E-ZAK-logo.png";
// import TenderArena from "../../../assets/companies/TenderArena-logo.png";
// import Pvu from "../../../assets/companies/PVU-logo.png";
// import Gemin from "../../../assets/companies/Gemin-logo.png";

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <Grid container justifyContent={"center"}>
          {/* Company logos */}
          {/* <Grid item xs={12}>
            <div className={classes.companiesWrapper}>
              <a href={"https://tenderarena.cz"} target={"_blank"} rel={"noreferrer"} title="TenderArena">
                <img
                  src={TenderArena}
                  alt={"TenderArena"}
                  width="100%"
                  height="100%"
                  className={classes.companyImage}
                />
              </a>
              <a href={"https://www.ezak.cz"} target={"_blank"} rel={"noreferrer"} title="E-ZAK">
                <img src={Ezak} alt={"E-ZAK"} width="100%" height="100%" className={classes.companyImage} />
              </a>
              <a href={"https://www.vhodne-uverejneni.cz"} target={"_blank"} rel={"noreferrer"} title="PVU">
                <img src={Pvu} alt={"PVU"} width="100%" height="100%" className={classes.companyImage} />
              </a>
              <a href={"https://www.tendermarket.cz"} target={"_blank"} rel={"noreferrer"} title="TenderMarket">
                <img
                  src={TenderMarket}
                  alt={"TenderMarket"}
                  width="100%"
                  height="100%"
                  className={classes.companyImage}
                />
              </a>
              <a href={"https://www.gemin.cz"} target={"_blank"} rel={"noreferrer"} title="GEMIN">
                <img src={Gemin} alt={"GEMIN"} width="100%" height="100%" className={classes.companyImage} />
              </a>
            </div>
          </Grid>
          <div className={classes.separator} /> */}
          <Grid item md={4} className={classes.footerText}>
            <div className={classes.title}>Kontakt</div>
            <div className={classes.iconContainer}>
              <span className={classes.icon}>
                <FontAwesomeIcon icon={faEnvelope} size={"lg"} style={{ color: COLORS.RED }} />
              </span>

              <a className={classes.link} href="mailto:podo@fen.cz">
                podo@fen.cz
              </a>
            </div>
            <div className={classes.iconContainer}>
              <span className={classes.icon}>
                <FontAwesomeIcon icon={faPhoneAlt} size={"lg"} style={{ color: COLORS.RED }} />
              </span>

              <span>
                <a className={classes.link} href="tel:+420 538 702 710">
                  +420 538 702 710
                </a>
              </span>
            </div>
            <div className={classes.iconContainer}>
              <span className={classes.icon}>
                <FontAwesomeIcon icon={faClock} size={"lg"} style={{ color: COLORS.RED }} />
              </span>
              <span>pracovní dny: 09:00 - 16:00</span>
            </div>
          </Grid>
          <Grid item md={5}>
            <div className={classes.title}>Info o provozovateli</div>
            <div className={classes.footerText}>
              <span>Spojené nástroje elektronické s.r.o.</span>
              <br />
              <a className={classes.link} href="mailto:informace@fen.cz">
                informace@fen.cz
              </a>
              <br />
              <span>ID datové schránky: 2dk7anf</span>
            </div>
          </Grid>
          <Grid item md={2}>
            <Link
              className={classes.link + " " + classes.mobileAppLink}
              to="/aktuality" title="Aktuality">
              Aktuality
            </Link>
            <Link
                className={classes.link + " " + classes.mobileAppLink}
                to="/statistiky" title="Statistiky">
              Statistiky
            </Link>
            <a
              className={classes.link + " " + classes.mobileAppLink}
              target={"_blank"}
              rel={"noopener noreferrer"}
              title={"Mobilní aplikace"}
              href="https://cdd.fen.cz/#/mobilni-aplikace"
            >
              Mobilní aplikace
            </a>
            <div className={classes.socialContainer}>
              {/*<a*/}
              {/*  href={"https://www.facebook.com/qcmbrno/"}*/}
              {/*  target={"_blank"}*/}
              {/*  rel={"noopener noreferrer"}*/}
              {/*  className={classes.socialIcon}*/}
              {/*  title={"Facebook"}*/}
              {/*>*/}
              {/*  <FontAwesomeIcon icon={faFacebook} size={"lg"} />*/}
              {/*</a>*/}
              <a
                href={"https://twitter.com/FEN2018a"}
                target={"_blank"}
                rel={"noopener noreferrer"}
                className={classes.socialIcon}
                title={"Twitter"}
              >
                {/* @ts-ignore*/}
                <FontAwesomeIcon icon={faXTwitter} size={"lg"} />
              </a>
              {/*<a*/}
              {/*  href={"https://www.youtube.com/channel/UCK3kcg-TyhpgL0cqSK5GKSw"}*/}
              {/*  target={"_blank"}*/}
              {/*  rel={"noopener noreferrer"}*/}
              {/*  className={classes.socialIcon}*/}
              {/*  title={"YouTube"}*/}
              {/*>*/}
              {/*  <FontAwesomeIcon icon={faYoutube} size={"lg"} />*/}
              {/*</a>*/}
            </div>


          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
