import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/root-reducer";
import Overview from "./Overview";
import useStyles from "./Overview.styles";
import { usePageViewMeta } from "../../../hooks/usePageViewMeta";
import { Redirect } from "react-router";
import { Alert } from "@material-ui/lab";
import LoadingBox from "../../../components/LoadingBox/LoadingBox";
import { selectUserInit } from "../../../redux/user/selectors";

const OverviewContainer: React.FC = () => {
  const classes = useStyles();
  const { user, cddInformation, status } = useSelector((state: RootState) => state.user);
  const isInit = useSelector(selectUserInit);

  const { setPageMeta } = usePageViewMeta();
  useEffect(() => {
    setPageMeta("Profil uživatele | Portál Dodavatele");
  }, []);

  if (status.loading || !isInit) {
    return <LoadingBox />;
  }

  if (status.error && status.message)
    return (
      <div className={classes.errorContainer}>
        <Alert severity="error" className={classes.errorMessage}>
          {status.message}
        </Alert>
      </div>
    );

  if (!user) return <Redirect to="/401-unauthorized?source=/profil" />;

  return <Overview user={user} cddInformation={cddInformation} />;
};

export default OverviewContainer;
