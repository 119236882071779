import { createStyles, makeStyles } from "@material-ui/core/styles";
import { COLORS, theme } from "../../theme/theme";

const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      background: COLORS.BGGREY,
      padding: "20px 0 80px 0",
      flexGrow: 2,
      position: "relative"
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 40
    },
    title: {
      fontSize: 36,
      fontWeight: 800,
      color: COLORS.BLUE,
      [theme.breakpoints.down("md")]: {
        fontSize: 28
      }
    },
    subtitle: {
      fontSize: ".9rem",
      color: "#767676",
      marginTop: 5
    },
    backButtonWrapper: {
      height: 35,
      paddingTop: 5
    },
    backButton: {
      textTransform: "none",
      fontWeight: 800,
      textDecoration: "underline"
    }
  })
);

export default useStyles;
