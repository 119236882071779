import React, { useEffect, useState } from "react";
import { usePageViewMeta } from "../../hooks/usePageViewMeta";
import PageWrap from "../../components/PageWrap/PageWrap";
import { Link, useParams } from "react-router-dom";
import { iNewsItem } from "../../typings/news";
import NewsApi from "../../api/NewsApi";
import InfoBox from "../../components/InfoBox/InfoBox";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import { format } from "date-fns";
import { cs } from "date-fns/locale";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import useStyles from "./News.styles";

const NewsDtail = () => {
  const classes = useStyles();
  const { setPageMeta } = usePageViewMeta();
  useEffect(() => {
    setPageMeta(
      "Aktualita | Portál Dodavatele",
      item?.Title ?? ''
    );
  }, []);

  const { slug } = useParams<{ slug: string }>();

  const [item, setItem] = useState<iNewsItem>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    NewsApi.getNewsDetail(slug)
      .then(res => {
        setItem(res);
      })
      .catch(e => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (error) {
    return <InfoBox text={"Chyba načtení aktuality"} />;
  }

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <PageWrap
      breadcrumbNavigation={[{ label: "Aktuality", path: "/aktuality" }, { label: item?.Title || "" }]}
      title={item?.Title || ""}
    >

      <small className={classes.date}>
        {item?.PublishedUtc ? format(new Date(item.PublishedUtc), "dd. MM. yyyy HH:mm", { locale: cs }) : ""}
      </small>

      <div className={classes.content} dangerouslySetInnerHTML={{ __html: item?.Content ?? "" }} />

      <Link to={`/aktuality`}>
        <Button
          startIcon={<FontAwesomeIcon icon={faReply} />}
          variant="contained"
          color="primary"
        >
          Zpět na aktuality
        </Button>
      </Link>

    </PageWrap>
  );
};

export default NewsDtail;
