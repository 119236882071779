import React from "react";
import { Chart as ChartJs2 } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  BarController,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import config from "./Chart.config";
import useStyles from "./Chart.styles";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/root-reducer";

ChartJS.register(CategoryScale, LinearScale, BarController, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const Chart: React.FC = () => {
  const classes = useStyles();
  const { loading, data } = useSelector((state: RootState) => state.global.statistics);

  const buildData = () => {
    if (!data || loading) return { datasets: [], labels: [] };
    const dataset = Object.values(data?.frequency).slice(0, 12);

    const labels = Object.keys(data.frequency).slice(0, 12);

    return { datasets: [{ data: dataset }], labels };
  };

  return (
    <div className={classes.container}>
      <ChartJs2 type="bar" data={buildData()} height={128} options={config} />
    </div>
  );
};

export default Chart;
