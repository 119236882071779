import { createStyles, makeStyles } from "@material-ui/core/styles";

const SlideInStyles = makeStyles(() =>
  createStyles({
    transition: {
      marginBottom: "1em",
      animationName: "$slideIn",
      animationDuration: "0.5s",
      animationTimingFunction: "ease-out",
      animationFillMode: "backwards"
    },
    "@keyframes slideIn": {
      from: {
        transform: "translate(0,3rem)",
        opacity: 0
      },
      to: {
        transform: "translate(0,0)",
        opacity: 1
      }
    }
  })
);

export default SlideInStyles;
